import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivateUsersInput = {
  userIds: Array<Scalars['ID']>;
};

export type ActiveSessions = {
  __typename?: 'ActiveSessions';
  sessions: Array<Session>;
};

export type ActiveSessionsInput = {
  /** SuperAdmin must provide this value!. For admin this value is ignored! */
  companyId?: Maybe<Scalars['ID']>;
};

export type ActiveSessionsPayload =
  | ActiveSessions
  | SessionStarted
  | SessionEnded
  | UserAddedToSession
  | UserRemovedFromSession;

export type AddUserToSessionInput = {
  sessionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: UserStatus;
  accessToken?: Maybe<Scalars['String']>;
};

export type ApkUploadChunkInfoInput = {
  fileId?: Maybe<Scalars['String']>;
  fileSize: Scalars['Float'];
  chunkIndex: Scalars['Int'];
  chunkCount: Scalars['Int'];
  chunkSize: Scalars['Int'];
};

export type ApkUploadResultPayload = {
  __typename?: 'ApkUploadResultPayload';
  fileId: Scalars['String'];
  confirmedChunk: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  package?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Application = {
  __typename?: 'Application';
  id: Scalars['ID'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  /** Latest published version */
  currentVersion?: Maybe<ApplicationVersion>;
  /** All versions */
  versions: Array<ApplicationVersionShort>;
  usageStatistics: ApplicationUsageStatistics;
  name: Scalars['String'];
  package: Scalars['String'];
  enabled: Scalars['Boolean'];
  systemApp: Scalars['Boolean'];
  status: ApplicationStatus;
};

export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<ApplicationEdge>>;
  nodes?: Maybe<Array<Application>>;
};

export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Application;
};

export type ApplicationFilterByInput = {
  field: ApplicationFilterField;
  query: Scalars['String'];
};

/** Properties by which application connections can be filtered */
export enum ApplicationFilterField {
  NAME = 'NAME',
}

export type ApplicationFilterInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ApplicationStatus>;
};

export type ApplicationOrderByInput = {
  direction: OrderDirection;
  field: ApplicationOrderField;
};

/** Properties by which application connections can be ordered. Default order is NAME ASC */
export enum ApplicationOrderField {
  ID = 'ID',
  NAME = 'NAME',
  PACKAGE = 'PACKAGE',
  ENABLED = 'ENABLED',
}

export type ApplicationPayload = {
  __typename?: 'ApplicationPayload';
  application?: Maybe<Application>;
  errors: Array<SaveApplicationErrors>;
};

export type ApplicationScene = {
  __typename?: 'ApplicationScene';
  id: Scalars['ID'];
  name: Scalars['String'];
  name_t: Scalars['String'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  imageUrl?: Maybe<Scalars['String']>;
  manualUrl?: Maybe<Scalars['String']>;
  parameters: Array<SceneParameter>;
  /** Application versions that have this scene */
  applicationVersions: Array<ApplicationVersionShort>;
  applicationSceneGroups?: Maybe<Array<ApplicationSceneGroup>>;
  applicationVersionId: Scalars['Float'];
};

export type ApplicationSceneFilterInput = {
  companyId?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  launchable?: Maybe<Scalars['Boolean']>;
};

export type ApplicationSceneGroup = {
  __typename?: 'ApplicationSceneGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  status: ApplicationSceneGroupStatus;
  mapSelectionEnabled: Scalars['Boolean'];
  mapSelectionId?: Maybe<Scalars['Int']>;
  /** Latest published version */
  currentScenes: Array<ApplicationScene>;
};

export type ApplicationSceneGroupConnection = {
  __typename?: 'ApplicationSceneGroupConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<ApplicationSceneGroupEdge>>;
  nodes?: Maybe<Array<ApplicationSceneGroup>>;
};

export type ApplicationSceneGroupEdge = {
  __typename?: 'ApplicationSceneGroupEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: ApplicationSceneGroup;
};

export type ApplicationSceneGroupFilterByInput = {
  field: ApplicationSceneGroupFilterField;
  query: Scalars['String'];
};

/** Properties by which application scene group connections can be filtered */
export enum ApplicationSceneGroupFilterField {
  NAME = 'NAME',
}

export type ApplicationSceneGroupFilterInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ApplicationSceneGroupStatus>;
};

export type ApplicationSceneGroupIdInput = {
  applicationSceneGroupId: Scalars['ID'];
};

export type ApplicationSceneGroupOrderByInput = {
  direction: OrderDirection;
  field: ApplicationSceneGroupOrderField;
};

/** Properties by which application scene group connections can be ordered. Default order is NAME ASC */
export enum ApplicationSceneGroupOrderField {
  ID = 'ID',
  NAME = 'NAME',
  ENABLED = 'ENABLED',
}

export enum ApplicationSceneGroupStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export type ApplicationSceneInput = {
  applicationVersionId: Scalars['ID'];
  applicationSceneId: Scalars['ID'];
};

export type ApplicationSetEnabledInputData = {
  applicationId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export enum ApplicationStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export type ApplicationUsageStatistics = {
  __typename?: 'ApplicationUsageStatistics';
  sessionCount: Scalars['Int'];
};

export type ApplicationVersion = {
  __typename?: 'ApplicationVersion';
  id: Scalars['ID'];
  version: Scalars['String'];
  published: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  application: Application;
  fileLink?: Maybe<Scalars['String']>;
  sha1?: Maybe<Scalars['String']>;
  scenes: Array<ApplicationScene>;
};

export type ApplicationVersionIdInput = {
  applicationVersionId: Scalars['ID'];
};

export type ApplicationVersionShort = {
  __typename?: 'ApplicationVersionShort';
  id: Scalars['ID'];
  version: Scalars['String'];
  published: Scalars['Boolean'];
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type AssignPlaylistTemplateFolderToCompanyInput = {
  folderId: Scalars['ID'];
  companyIds: Array<Scalars['ID']>;
};

export type CertificateTemplate = {
  __typename?: 'CertificateTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  fileLink: Scalars['String'];
  sendCcTo?: Maybe<Scalars['String']>;
  sendToTrainees: Scalars['Boolean'];
  url: Scalars['String'];
};

export type ChangeCompanyInput = {
  companyId: Scalars['ID'];
  newStatus: CompanyStatus;
};

export type ChangeLanguageInput = {
  languageCode: Scalars['String'];
};

export type CheckAppInfo = {
  __typename?: 'CheckAppInfo';
  name: Scalars['String'];
  package: Scalars['String'];
  version: Scalars['String'];
  fileName: Scalars['String'];
  sha1?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CheckInstalledAppsCommand = {
  __typename?: 'CheckInstalledAppsCommand';
  apps: Array<CheckAppInfo>;
  command: Scalars['String'];
};

export type CheckUserDuplicityInput = {
  companyId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
};

export type CheckUserDuplicityResult = {
  __typename?: 'CheckUserDuplicityResult';
  duplicate: Scalars['Boolean'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  status: CompanyStatus;
  defaultLoginType?: Maybe<LoginType>;
  activeLoginType?: Maybe<LoginType>;
  streamType: StreamType;
  statisctics: CompanyStatistics;
  parentCompanyId?: Maybe<Scalars['String']>;
  parentCompany?: Maybe<Company>;
  helpFaqId?: Maybe<Scalars['Int']>;
  helpFaq?: Maybe<HelpFaq>;
  logoUrl?: Maybe<Scalars['String']>;
  hasCustomLogo: Scalars['Boolean'];
  features: Array<CompanyFeature>;
  availableHelpFaq: HelpFaq;
  statistics: CompanyStatistics;
};

export enum CompanyFeature {
  LAUNCH_GROUP_SESSION = 'LAUNCH_GROUP_SESSION',
  PATIENT_LOGIN = 'PATIENT_LOGIN',
}

export type CompanyFilterInput = {
  status?: Maybe<CompanyStatus>;
};

export type CompanyIdInput = {
  companyId: Scalars['ID'];
};

export type CompanyIdOptionalInput = {
  /** SuperAdmin must provide this value!. For admin this value is ignored! */
  companyId?: Maybe<Scalars['ID']>;
};

export type CompanySceneInput = {
  companyId: Scalars['ID'];
  applicationSceneId: Scalars['ID'];
};

export type CompanyStatistics = {
  __typename?: 'CompanyStatistics';
  activeUsers: Scalars['Int'];
  apiKeys: Scalars['Int'];
  headsets: Scalars['Int'];
  vrBoxes: Scalars['Int'];
  scenes: Scalars['Int'];
  userGroups: Scalars['Int'];
  features: Scalars['Int'];
};

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum CrateApplicationSceneGroupErrorCode {
  APPLICATION_SCENE_GROUP_WITH_SAME_NAME_ALREADY_EXISTS = 'APPLICATION_SCENE_GROUP_WITH_SAME_NAME_ALREADY_EXISTS',
}

export enum CrateCompanyError {
  COMPANY_WITH_SAME_NAME_ALREADY_EXISTS = 'COMPANY_WITH_SAME_NAME_ALREADY_EXISTS',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  PARENT_COMPANY_NOT_EXISTS = 'PARENT_COMPANY_NOT_EXISTS',
  PARENT_COMPANY_HAS_DIFFERENT_TYPE = 'PARENT_COMPANY_HAS_DIFFERENT_TYPE',
}

export type CreateApiKeyInput = {
  name: Scalars['String'];
  companyId: Scalars['String'];
};

export type CreateApplicationInputData = {
  name: Scalars['String'];
  systemApp: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  fileId: Scalars['String'];
};

export type CreateApplicationSceneGroupError = {
  __typename?: 'CreateApplicationSceneGroupError';
  code: CrateApplicationSceneGroupErrorCode;
  message: Scalars['String'];
};

export type CreateApplicationSceneGroupInput = {
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  mapSelectionEnabled?: Maybe<Scalars['Boolean']>;
  mapSelectionId?: Maybe<Scalars['Int']>;
};

export type CreateApplicationSceneGroupPayload = {
  __typename?: 'CreateApplicationSceneGroupPayload';
  applicationSceneGroup?: Maybe<ApplicationSceneGroup>;
  errors: Array<CreateApplicationSceneGroupError>;
};

export type CreateCertificateTemplateInput = {
  name: Scalars['String'];
  playlistId: Scalars['String'];
  sendCcTo: Scalars['String'];
  sendToTrainees: Scalars['Boolean'];
};

export type CreateCompanyError = {
  __typename?: 'CreateCompanyError';
  argument: Scalars['String'];
  code: CrateCompanyError;
  message: Scalars['String'];
};

export type CreateCompanyInput = {
  parentCompanyId?: Maybe<Scalars['ID']>;
  defaultLoginType?: Maybe<LoginType>;
  name: Scalars['String'];
  streamType: StreamType;
};

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company?: Maybe<Company>;
  errors: Array<CreateCompanyError>;
};

export type CreateHelpFaqInput = {
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
};

export type CreateManualInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type CreatePlaylistTemplateFolderInput = {
  name: Scalars['String'];
  companyId: Scalars['String'];
};

export type CreatePlaylistTemplateInput = {
  name: Scalars['String'];
  folderIds?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
  enforceOrder?: Maybe<Scalars['Boolean']>;
  scenes: Array<PlaylistTemplateItemInput>;
};

export type CreateSessionInput = {
  vrDeviceIds: Array<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  launch: Scalars['Boolean'];
  scenes: Array<CreateSessionPlaylistItemInput>;
  externalId?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
};

export type CreateSessionPlaylistItemInput = {
  sceneId: Scalars['ID'];
  params?: Maybe<Array<CreateSessionPlaylistItemParamInput>>;
};

export type CreateSessionPlaylistItemParamInput = {
  paramId?: Maybe<Scalars['ID']>;
  valueId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateUserGroupInput = {
  /** SuperAdmin must provide this value!. For admin this value is ignored! */
  companyId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateUserInput = {
  companyId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  loginType?: Maybe<LoginType>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  groups?: Maybe<Array<Scalars['String']>>;
  diagnosis?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  note?: Maybe<Scalars['String']>;
};

export type CreateVrBoxInput = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
};

export type DebugReport = {
  __typename?: 'DebugReport';
  id: Scalars['ID'];
  ordinal: Scalars['Int'];
  version: Scalars['String'];
  reportData: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  scene: ApplicationScene;
};

export type DeleteCertificateTemplateInput = {
  certificateId: Scalars['ID'];
};

export type DeleteManualInput = {
  manualId: Scalars['ID'];
};

export type DeletePlaylistTemplateFolderInput = {
  id: Scalars['ID'];
};

export type DeletePlaylistTemplateInput = {
  id: Scalars['ID'];
};

export type DeleteSessionInput = {
  sessionId: Scalars['ID'];
};

export type DeviceSessionEnded = {
  __typename?: 'DeviceSessionEnded';
  deviceId: Scalars['String'];
};

export type DeviceSessionStarted = {
  __typename?: 'DeviceSessionStarted';
  deviceId: Scalars['String'];
  sessionId: Scalars['String'];
};

export enum DeviceState {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  IN_USE = 'IN_USE',
}

export type EditApiKeyInput = {
  apiKeyId: Scalars['String'];
  status?: Maybe<UserStatus>;
  name?: Maybe<Scalars['String']>;
};

export type EditApplicationInputData = {
  applicationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  systemApp?: Maybe<Scalars['Boolean']>;
  fileId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ApplicationStatus>;
};

export type EditApplicationSceneGroupInput = {
  applicationSceneGroupId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ApplicationSceneGroupStatus>;
  mapSelectionEnabled?: Maybe<Scalars['Boolean']>;
  mapSelectionId?: Maybe<Scalars['Int']>;
};

export type EditApplicationSceneGroupPayload = {
  __typename?: 'EditApplicationSceneGroupPayload';
  applicationSceneGroup?: Maybe<ApplicationSceneGroup>;
  errors: Array<CreateApplicationSceneGroupError>;
};

export type EditApplicationSceneInput = {
  applicationVersionId: Scalars['ID'];
  applicationSceneId: Scalars['ID'];
  enabled?: Maybe<Scalars['Boolean']>;
  applicationSceneGroupIds?: Maybe<Array<Scalars['ID']>>;
  sceneParameterValue?: Maybe<Array<SetSceneParameterValueInput>>;
  name_t?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EditCertificateTemplateInput = {
  certificateId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  playlistId: Scalars['String'];
  sendToTrainees?: Maybe<Scalars['Boolean']>;
  sendCcTo?: Maybe<Scalars['String']>;
};

export type EditCompanyFeaturesInput = {
  companyId: Scalars['ID'];
  features: Array<CompanyFeature>;
};

export type EditCompanyInput = {
  companyId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  helpFaqId?: Maybe<Scalars['ID']>;
  defaultLoginType?: Maybe<LoginType>;
};

export type EditHelpFaqInput = {
  helpFaqId: Scalars['ID'];
  name: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
};

export type EditManualInput = {
  manualId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type EditPlaylistTemplateFolderInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  playlistTemplateIds?: Maybe<Array<Scalars['ID']>>;
};

export type EditPlaylistTemplateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  addedFolders?: Maybe<Array<Scalars['String']>>;
  removedFolders?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
  enforceOrder?: Maybe<Scalars['Boolean']>;
  scenes?: Maybe<Array<PlaylistTemplateItemInput>>;
};

export type EditUserGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditUserInput = {
  userId: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  loginType?: Maybe<LoginType>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  groups?: Maybe<Array<Scalars['String']>>;
  diagnosis?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  note?: Maybe<Scalars['String']>;
};

export type EditVrBoxInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditVrDeviceInput = {
  vrDeviceId: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  vrBoxId?: Maybe<Scalars['ID']>;
};

export type EmptyEvent = {
  __typename?: 'EmptyEvent';
  command: Scalars['String'];
};

export type EndSessionInput = {
  sessionId: Scalars['ID'];
};

/** Possible categories of feedback. */
export enum FeedbackCategory {
  A = 'A',
  B = 'B',
  Q = 'Q',
  S = 'S',
}

export type FeedbackInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  category: FeedbackCategory;
  categoryExplanation?: Maybe<Scalars['String']>;
};

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  ownerId?: Maybe<Scalars['ID']>;
  playlistTemplates: Array<PlaylistTemplate>;
  playlistTemplatesCount: Scalars['Int'];
  assignedCompanies: Array<Company>;
};

export type FolderEdge = {
  __typename?: 'FolderEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Folder;
};

export type GetApiKeysInput = {
  companyId: Scalars['String'];
};

export type GetApplicationInputData = {
  applicationId: Scalars['ID'];
};

export type GetCompanyFoldersInput = {
  companyId: Scalars['ID'];
};

export type GetFolderInput = {
  id: Scalars['ID'];
};

export type GetPlaylistTemplateFoldersInput = {
  playlistTemplateId: Scalars['ID'];
};

export type GetUnassignedPlaylistTemplatesInput = {
  folderId: Scalars['ID'];
};

export type GetUserTrainingInput = {
  userTrainingId: Scalars['ID'];
};

export type GetUserTrainingsInput = {
  userId: Scalars['ID'];
};

export type HeadMovement = {
  __typename?: 'HeadMovement';
  key: Scalars['String'];
  sceneId: Scalars['String'];
  sceneName: Scalars['String'];
  data: Array<MovementVector>;
};

export type HeadRotation = {
  __typename?: 'HeadRotation';
  /** back tilt degrees */
  minX: Scalars['Float'];
  /** front tilt derees */
  maxX: Scalars['Float'];
  /** left rotation degrees */
  minY: Scalars['Float'];
  /** right rotation derees */
  maxY: Scalars['Float'];
  /** left tilt degrees */
  minZ: Scalars['Float'];
  /** right tilt derees */
  maxZ: Scalars['Float'];
};

export type HeadRotationSeries = {
  __typename?: 'HeadRotationSeries';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  /** back tilt degrees */
  minX: Scalars['Float'];
  /** front tilt derees */
  maxX: Scalars['Float'];
  /** left rotation degrees */
  minY: Scalars['Float'];
  /** right rotation derees */
  maxY: Scalars['Float'];
  /** left tilt degrees */
  minZ: Scalars['Float'];
  /** right tilt derees */
  maxZ: Scalars['Float'];
};

export type HelpFaq = {
  __typename?: 'HelpFaq';
  id: Scalars['ID'];
  email_t: Scalars['String'];
  name_t: Scalars['String'];
  content_t?: Maybe<Scalars['String']>;
};

export type HelpFaqIdInput = {
  helpFaqId: Scalars['ID'];
};

export type HelpFaqResultInput = {
  slideId: Scalars['String'];
  values?: Maybe<Array<HelpFaqResultValueInput>>;
};

export type HelpFaqResultValueInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type IceServer = {
  __typename?: 'IceServer';
  credential?: Maybe<Scalars['String']>;
  credentialType?: Maybe<Scalars['String']>;
  urls: Array<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type IceServerConfig = {
  __typename?: 'IceServerConfig';
  iceServers: Array<IceServer>;
};

export type Language = {
  __typename?: 'Language';
  /** language code according to ISO_639-1 */
  code: Scalars['String'];
  name: Scalars['String'];
};

export type LaunchAppCommand = {
  __typename?: 'LaunchAppCommand';
  package: Scalars['String'];
  command: Scalars['String'];
  sessionId: Scalars['String'];
  vrMedicalData: Scalars['String'];
};

export type LaunchNextInput = {
  sessionId: Scalars['String'];
  sessionPlaylistItemId?: Maybe<Scalars['ID']>;
};

export type LaunchUrlCommand = {
  __typename?: 'LaunchUrlCommand';
  url: Scalars['String'];
  command: Scalars['String'];
};

export type LaunchUrlInput = {
  vrDeviceId: Scalars['ID'];
  url: Scalars['String'];
};

export type LaunchableApplicationScene = {
  __typename?: 'LaunchableApplicationScene';
  applicationVersion: ApplicationVersion;
  scene: ApplicationScene;
};

export enum LoginType {
  PHONE = 'PHONE',
  USERNAME = 'USERNAME',
}

export type Manual = {
  __typename?: 'Manual';
  id: Scalars['ID'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  url: Scalars['String'];
};

export type MovementVector = {
  __typename?: 'MovementVector';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
  /** Time since start of application when this value was measured in seconds */
  time: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCompany: CreateCompanyPayload;
  updateCompanyLogo: Company;
  changeCompanyStatus: Company;
  editCompany: Company;
  editCompanyFeatures: Company;
  addApplicationScene: Scalars['Boolean'];
  addAllApplicationScenes: Scalars['Boolean'];
  removeApplicationScene: Scalars['Boolean'];
  createHelpFaq: HelpFaq;
  sendHelpFaqResult: Scalars['Boolean'];
  editHelpFaq: HelpFaq;
  resetPassword: User;
  suspendUsers: Array<User>;
  activateUsers: Array<User>;
  changeLanguage: User;
  rejectPersonalData: Scalars['Boolean'];
  checkUserDuplicity: CheckUserDuplicityResult;
  createUser: User;
  editUser: User;
  createUserGroup: UserGroup;
  editUserGroup: UserGroup;
  removeUserGroup: Scalars['Boolean'];
  addUserToGroup: Scalars['Boolean'];
  removeUserFromGroup: Scalars['Boolean'];
  createApiKey: ApiKey;
  editApiKey: ApiKey;
  regenerateApiKey: ApiKey;
  registerVrDevice: VrDevice;
  launchUrl: Scalars['Boolean'];
  editVrDevice: VrDevice;
  updateAppsOnVrDevice: Scalars['Boolean'];
  vrDeviceLogin: VrDeviceLoginResponse;
  updateVrDeviceStatus: Scalars['Boolean'];
  vrDeviceAppCheck: Scalars['Boolean'];
  vrDeviceLaunchConfirmed: Scalars['Boolean'];
  vrDeviceAppDownloadFinished: Scalars['Boolean'];
  vrDeviceLaunchPlaylistTemplate: Scalars['Boolean'];
  updateVrDeviceApps: Scalars['Boolean'];
  restartDevice: Scalars['Boolean'];
  deleteVrDevice: Scalars['Boolean'];
  increaseDeviceVolume: Scalars['Boolean'];
  decreaseDeviceVolume: Scalars['Boolean'];
  setVrDeviceOsUpdatesEnabled?: Maybe<VrDevice>;
  webRtcNewOffer: Scalars['String'];
  webRtcAnswer: Scalars['Boolean'];
  webRtcCandidate: Scalars['Boolean'];
  setApplicationEnabled: Application;
  uploadApk: ApkUploadResultPayload;
  createApplication: ApplicationPayload;
  editApplication: ApplicationPayload;
  publishApplicationVersion: ApplicationVersion;
  editApplicationScene: ApplicationScene;
  uploadApplicationSceneManual: ApplicationScene;
  createApplicationSceneGroup: CreateApplicationSceneGroupPayload;
  editApplicationSceneGroup: EditApplicationSceneGroupPayload;
  createSession: Array<Session>;
  addUserToSession: Session;
  removeUserFromSession: Session;
  endSession: Session;
  launchNextInVrSession: Session;
  deleteSession: Scalars['Boolean'];
  stopSessionOnDevices: Scalars['Boolean'];
  createVrBox: VrBox;
  editVrBox: VrBox;
  deleteVrBox: Scalars['Boolean'];
  createPlaylistTemplate: PlaylistTemplate;
  editPlaylistTemplate: PlaylistTemplate;
  deletePlaylistTemplate: Scalars['Boolean'];
  changePlaylistTemplateCompanyAssignment: PlaylistTemplate;
  removeTrainingAssignments: Scalars['Boolean'];
  addTrainingAssigmentForUsers: Array<TrainingAssignment>;
  addTrainingAssigmentsForUser: Array<TrainingAssignment>;
  addTrainingAssigmentForUserGroups: Array<TrainingAssignment>;
  editTrainingAssignment: TrainingAssignment;
  generateUserTrainingCertificate: UserTrainingAssignment;
  createCertificateTemplate: CertificateTemplate;
  editCertificateTemplate: CertificateTemplate;
  deleteCertificateTemplate: Scalars['Boolean'];
  editPlaylistTemplateFolder: Folder;
  deletePlaylistTemplateFolder: Scalars['Boolean'];
  createPlaylistTemplateFolder: Folder;
  assignPlaylistTemplateFolderToCompany: Scalars['Boolean'];
  changePlaylistTemplateFoldersCompanyAssignment: PlaylistTemplate;
  sessionPlaylistItemReportsMutation: Array<SessionPlaylistItemReport>;
  submitFeedback: Scalars['Boolean'];
  createManual: Manual;
  editManual: Manual;
  deleteManual: Scalars['Boolean'];
};

export type MutationCreateCompanyArgs = {
  createCompany: CreateCompanyInput;
};

export type MutationUpdateCompanyLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
  companyInput: CompanyIdInput;
};

export type MutationChangeCompanyStatusArgs = {
  changeCompanyInput: ChangeCompanyInput;
};

export type MutationEditCompanyArgs = {
  editCompanyInput: EditCompanyInput;
};

export type MutationEditCompanyFeaturesArgs = {
  input: EditCompanyFeaturesInput;
};

export type MutationAddApplicationSceneArgs = {
  companySceneInput: CompanySceneInput;
};

export type MutationAddAllApplicationScenesArgs = {
  companyIdInput: CompanyIdInput;
};

export type MutationRemoveApplicationSceneArgs = {
  companySceneInput: CompanySceneInput;
};

export type MutationCreateHelpFaqArgs = {
  createHelpFaq: CreateHelpFaqInput;
};

export type MutationSendHelpFaqResultArgs = {
  sendHelpFaqResult: SendHelpFaqResultInput;
};

export type MutationEditHelpFaqArgs = {
  editHelpFaq: EditHelpFaqInput;
};

export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};

export type MutationSuspendUsersArgs = {
  suspendUsersInput: SuspendUsersInput;
};

export type MutationActivateUsersArgs = {
  activateUsersInput: ActivateUsersInput;
};

export type MutationChangeLanguageArgs = {
  changeLanguageInput: ChangeLanguageInput;
};

export type MutationRejectPersonalDataArgs = {
  rejectPersonalDataInput: RejectPersonalDataInput;
};

export type MutationCheckUserDuplicityArgs = {
  input: CheckUserDuplicityInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationEditUserArgs = {
  editUserInput: EditUserInput;
};

export type MutationCreateUserGroupArgs = {
  input: CreateUserGroupInput;
};

export type MutationEditUserGroupArgs = {
  input: EditUserGroupInput;
};

export type MutationRemoveUserGroupArgs = {
  input: RemoveUserGroupInput;
};

export type MutationAddUserToGroupArgs = {
  input: UserGroupAssignmentInput;
};

export type MutationRemoveUserFromGroupArgs = {
  input: UserGroupAssignmentInput;
};

export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

export type MutationEditApiKeyArgs = {
  input: EditApiKeyInput;
};

export type MutationRegenerateApiKeyArgs = {
  input: RegenerateApiKeyInput;
};

export type MutationRegisterVrDeviceArgs = {
  deviceRegistration: RegisterDeviceInput;
};

export type MutationLaunchUrlArgs = {
  input: LaunchUrlInput;
};

export type MutationEditVrDeviceArgs = {
  editVrDeviceInput: EditVrDeviceInput;
};

export type MutationUpdateAppsOnVrDeviceArgs = {
  input: VrDeviceIdInput;
};

export type MutationVrDeviceLoginArgs = {
  osVersion?: Maybe<Scalars['String']>;
  deviceOwner?: Maybe<Scalars['Boolean']>;
  deviceId: Scalars['String'];
};

export type MutationUpdateVrDeviceStatusArgs = {
  status: UpdateVrDeviceStatusInput;
};

export type MutationVrDeviceLaunchConfirmedArgs = {
  input: VrDeviceLaunchConfirmedInput;
};

export type MutationVrDeviceAppDownloadFinishedArgs = {
  vrDeviceAppDownloadFinished: VrDeviceAppDownloadFinishedInput;
};

export type MutationVrDeviceLaunchPlaylistTemplateArgs = {
  input: VrDeviceLaunchPlaylistsTemplateInput;
};

export type MutationUpdateVrDeviceAppsArgs = {
  apps: UpdateVrDeviceAppsInput;
};

export type MutationRestartDeviceArgs = {
  input: VrDeviceInput;
};

export type MutationDeleteVrDeviceArgs = {
  deleteVrDeviceInput: VrDeviceIdInput;
};

export type MutationIncreaseDeviceVolumeArgs = {
  input: VrDeviceInput;
};

export type MutationDecreaseDeviceVolumeArgs = {
  input: VrDeviceInput;
};

export type MutationSetVrDeviceOsUpdatesEnabledArgs = {
  input: VrDeviceSysUpdateEnabledInput;
};

export type MutationWebRtcNewOfferArgs = {
  offerReq: WebRtcNewOfferInput;
};

export type MutationWebRtcAnswerArgs = {
  answer: WebRtcAsnwerSessionDescriptionInput;
};

export type MutationWebRtcCandidateArgs = {
  candidate: WebRtcCandidateMessageInput;
};

export type MutationSetApplicationEnabledArgs = {
  status: ApplicationSetEnabledInputData;
};

export type MutationUploadApkArgs = {
  input: ApkUploadChunkInfoInput;
  file: Scalars['Upload'];
};

export type MutationCreateApplicationArgs = {
  app: CreateApplicationInputData;
};

export type MutationEditApplicationArgs = {
  app: EditApplicationInputData;
};

export type MutationPublishApplicationVersionArgs = {
  applicationVersionInput: ApplicationVersionIdInput;
};

export type MutationEditApplicationSceneArgs = {
  image?: Maybe<Scalars['Upload']>;
  editApplicationSceneInput: EditApplicationSceneInput;
};

export type MutationUploadApplicationSceneManualArgs = {
  manual: Scalars['Upload'];
  input: ApplicationSceneInput;
};

export type MutationCreateApplicationSceneGroupArgs = {
  createApplicationSceneGroup: CreateApplicationSceneGroupInput;
};

export type MutationEditApplicationSceneGroupArgs = {
  editApplicationSceneGroupInput: EditApplicationSceneGroupInput;
};

export type MutationCreateSessionArgs = {
  createSessionInput: CreateSessionInput;
};

export type MutationAddUserToSessionArgs = {
  addUserToSessionInput: AddUserToSessionInput;
};

export type MutationRemoveUserFromSessionArgs = {
  removeUserFromSessionInput: RemoveUserFromSessionInput;
};

export type MutationEndSessionArgs = {
  endSessionInput: EndSessionInput;
};

export type MutationLaunchNextInVrSessionArgs = {
  nextInput: LaunchNextInput;
};

export type MutationDeleteSessionArgs = {
  deleteSessionInput: DeleteSessionInput;
};

export type MutationStopSessionOnDevicesArgs = {
  deviceIds: Array<Scalars['String']>;
};

export type MutationCreateVrBoxArgs = {
  vrBoxInput: CreateVrBoxInput;
};

export type MutationEditVrBoxArgs = {
  vrBoxInput: EditVrBoxInput;
};

export type MutationDeleteVrBoxArgs = {
  vrBoxIdInput: VrBoxIdInput;
};

export type MutationCreatePlaylistTemplateArgs = {
  input: CreatePlaylistTemplateInput;
};

export type MutationEditPlaylistTemplateArgs = {
  input: EditPlaylistTemplateInput;
};

export type MutationDeletePlaylistTemplateArgs = {
  input: DeletePlaylistTemplateInput;
};

export type MutationChangePlaylistTemplateCompanyAssignmentArgs = {
  input: PlaylistTemplateAssignmentInput;
};

export type MutationRemoveTrainingAssignmentsArgs = {
  input: TrainingAssignmentRemoveInput;
};

export type MutationAddTrainingAssigmentForUsersArgs = {
  input: TrainingAssignmentUsersInput;
};

export type MutationAddTrainingAssigmentsForUserArgs = {
  input: TrainingAssignmentsUserInput;
};

export type MutationAddTrainingAssigmentForUserGroupsArgs = {
  input: TrainingAssignmentUserGroupsInput;
};

export type MutationEditTrainingAssignmentArgs = {
  input: TrainingAssignmentEditInput;
};

export type MutationGenerateUserTrainingCertificateArgs = {
  input: GetUserTrainingInput;
};

export type MutationCreateCertificateTemplateArgs = {
  file: Scalars['Upload'];
  input: CreateCertificateTemplateInput;
};

export type MutationEditCertificateTemplateArgs = {
  file?: Maybe<Scalars['Upload']>;
  input: EditCertificateTemplateInput;
};

export type MutationDeleteCertificateTemplateArgs = {
  input: DeleteCertificateTemplateInput;
};

export type MutationEditPlaylistTemplateFolderArgs = {
  input: EditPlaylistTemplateFolderInput;
};

export type MutationDeletePlaylistTemplateFolderArgs = {
  input: DeletePlaylistTemplateFolderInput;
};

export type MutationCreatePlaylistTemplateFolderArgs = {
  input: CreatePlaylistTemplateFolderInput;
};

export type MutationAssignPlaylistTemplateFolderToCompanyArgs = {
  input: AssignPlaylistTemplateFolderToCompanyInput;
};

export type MutationChangePlaylistTemplateFoldersCompanyAssignmentArgs = {
  input: PlaylistTemplateFoldersAssignmentInput;
};

export type MutationSessionPlaylistItemReportsMutationArgs = {
  filter: SessionPlaylistItemReportFilter;
};

export type MutationSubmitFeedbackArgs = {
  files?: Maybe<Array<Scalars['Upload']>>;
  feedbackInput: FeedbackInput;
};

export type MutationCreateManualArgs = {
  file: Scalars['Upload'];
  manualInput: CreateManualInput;
};

export type MutationEditManualArgs = {
  file?: Maybe<Scalars['Upload']>;
  editManualInput: EditManualInput;
};

export type MutationDeleteManualArgs = {
  deleteManualInput: DeleteManualInput;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PageCursor = {
  __typename?: 'PageCursor';
  /** Cursor that can be passed to "after" parameter to obtain this page */
  cursor: Scalars['String'];
  /**  Page number, indexed from 1 */
  pageNumber: Scalars['Int'];
};

export type PageCursors = {
  __typename?: 'PageCursors';
  /** Cursor to first page, can be null if we have only single page */
  first?: Maybe<PageCursor>;
  /** Cursor to last page, can be null if we have only single page */
  last?: Maybe<PageCursor>;
  /** Cursor to next page, can be null if there's no next page */
  next?: Maybe<PageCursor>;
  /** Cursor to previous page, can be null if there's no previous page */
  previous?: Maybe<PageCursor>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PlaylistItemTemplateParamInput = {
  paramId: Scalars['ID'];
  valueId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PlaylistTemplate = {
  __typename?: 'PlaylistTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  enabled: Scalars['Boolean'];
  enforceOrder: Scalars['Boolean'];
  suspectInvalid: Scalars['Boolean'];
  owner: User;
  items: Array<PlaylistTemplateItem>;
  certificateTemplate?: Maybe<CertificateTemplate>;
  assignedToCompanies: Array<Company>;
};

export type PlaylistTemplateAssignmentInput = {
  playlistTemplateId: Scalars['ID'];
  assignedCompanies: Array<Scalars['ID']>;
  unAssignedCompanies: Array<Scalars['ID']>;
};

export type PlaylistTemplateConnection = {
  __typename?: 'PlaylistTemplateConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<PlaylistTemplateEdge>>;
  nodes?: Maybe<Array<PlaylistTemplate>>;
};

export type PlaylistTemplateEdge = {
  __typename?: 'PlaylistTemplateEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: PlaylistTemplate;
};

export type PlaylistTemplateFilterByInput = {
  field: PlaylistTemplateFilterField;
  query: Scalars['String'];
};

/** Properties by which playlist template connections can be filtered */
export enum PlaylistTemplateFilterField {
  NAME = 'NAME',
}

export type PlaylistTemplateFilterInput = {
  companyId?: Maybe<Scalars['ID']>;
  ownerId?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  ignoreIds?: Maybe<Array<Scalars['ID']>>;
  folderId?: Maybe<Scalars['ID']>;
};

export type PlaylistTemplateFolderConnection = {
  __typename?: 'PlaylistTemplateFolderConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<FolderEdge>>;
  nodes?: Maybe<Array<Folder>>;
};

export type PlaylistTemplateFoldersAssignmentInput = {
  folderId: Scalars['ID'];
  assignedCompanies: Array<Scalars['ID']>;
  unAssignedCompanies: Array<Scalars['ID']>;
};

export type PlaylistTemplateItem = {
  __typename?: 'PlaylistTemplateItem';
  id: Scalars['ID'];
  ordinal: Scalars['Int'];
  scene: ApplicationScene;
  params: Array<PlaylistTemplateItemParam>;
};

export type PlaylistTemplateItemInput = {
  sceneId: Scalars['ID'];
  params: Array<PlaylistItemTemplateParamInput>;
};

export type PlaylistTemplateItemParam = {
  __typename?: 'PlaylistTemplateItemParam';
  paramId: Scalars['ID'];
  name: Scalars['String'];
  name_t: Scalars['String'];
  valueId?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
  value_t: Scalars['String'];
};

export type PlaylistTemplateOrderByInput = {
  direction: OrderDirection;
  field: PlaylistTemplateOrderField;
};

/** Properties by which playlist template connections can be ordered. */
export enum PlaylistTemplateOrderField {
  ID = 'ID',
  NAME = 'NAME',
}

export type PlaylistTemplateQueryInput = {
  playlistTemplateId: Scalars['ID'];
};

export type PlaylistTemplatesFoldersFilterByInput = {
  field: PlaylistTemplatesFoldersFilterField;
  query: Scalars['String'];
};

/** Properties by which playlist template folder connections can be filtered */
export enum PlaylistTemplatesFoldersFilterField {
  NAME = 'NAME',
}

export type PlaylistTemplatesFoldersFilterInput = {
  companyId: Scalars['ID'];
  ownerId?: Maybe<Scalars['ID']>;
};

export type PlaylistTemplatesFoldersOrderByInput = {
  direction: OrderDirection;
  field: PlaylistTemplatesFoldersOrderField;
};

/** Properties by which playlist template folder connections can be ordered. */
export enum PlaylistTemplatesFoldersOrderField {
  ID = 'ID',
  NAME = 'NAME',
}

export type Query = {
  __typename?: 'Query';
  company: Company;
  companyApplicationScenes: Array<ApplicationScene>;
  assignableApplicationScenes: Array<ApplicationScene>;
  companies: Array<Company>;
  directSubsidiaries: Array<Company>;
  helpFaq: HelpFaq;
  availableHelpFaq: HelpFaq;
  helpFaqs: Array<HelpFaq>;
  user: User;
  users: UserConnection;
  userGroups: Array<UserGroup>;
  apiKeys: Array<ApiKey>;
  userDiagnoses: Array<UserDiagnosis>;
  vrDevice: VrDevice;
  vrDeviceAppDownload: VrDeviceAppDownloadPayload;
  vrDevicePlaylistTemplates: Array<PlaylistTemplate>;
  vrDevicesPaginated: VrDeviceConnection;
  vrDevices: Array<VrDevice>;
  iceServerConfig: IceServerConfig;
  applications: ApplicationConnection;
  application: Application;
  applicationVersion: ApplicationVersion;
  applicationScene: ApplicationScene;
  applicationScenes: Array<ApplicationScene>;
  allApplicationScenes: Array<ApplicationScene>;
  applicationSceneGroup: ApplicationSceneGroup;
  applicationSceneGroupsWithMapSelection: Array<ApplicationSceneGroup>;
  applicationSceneGroups: ApplicationSceneGroupConnection;
  session: Session;
  sessions: SessionConnection;
  getRolePermissions: Array<RolePermissions>;
  vrBoxes: Array<VrBox>;
  playlistTemplate: PlaylistTemplate;
  unassignedPlaylistTemplates: Array<PlaylistTemplate>;
  playlistTemplates: PlaylistTemplateConnection;
  trainingAssignments: Array<TrainingAssignment>;
  userTrainingAssignments: Array<UserTrainingAssignment>;
  userTrainingAssignment: UserTrainingAssignment;
  certificateTemplates: Array<CertificateTemplate>;
  folders: Array<Folder>;
  playlistTemplateFolders: Array<Folder>;
  playlistTemplatesFolders: PlaylistTemplateFolderConnection;
  folder: Folder;
  debugReports: Array<DebugReport>;
  sessionPlaylistItemReports: Array<SessionPlaylistItemReport>;
  userReports: UserReport;
  userTrainingReports: UserTrainingReport;
  manuals: Array<Manual>;
  languages: Array<Language>;
};

export type QueryCompanyArgs = {
  input: CompanyIdInput;
};

export type QueryCompanyApplicationScenesArgs = {
  input: CompanyIdInput;
};

export type QueryAssignableApplicationScenesArgs = {
  input: CompanyIdInput;
};

export type QueryCompaniesArgs = {
  filter?: Maybe<CompanyFilterInput>;
};

export type QueryDirectSubsidiariesArgs = {
  subsidiaryInput: SubsidiaryInput;
};

export type QueryHelpFaqArgs = {
  input: HelpFaqIdInput;
};

export type QueryUserArgs = {
  userInput?: Maybe<UserInput>;
};

export type QueryUsersArgs = {
  filter?: Maybe<UsersFilterInput>;
  filterBy?: Maybe<UserFilterByInput>;
  orderBy?: Maybe<UserOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QueryUserGroupsArgs = {
  input: CompanyIdOptionalInput;
};

export type QueryApiKeysArgs = {
  input: GetApiKeysInput;
};

export type QueryVrDeviceArgs = {
  vrDeviceInput: VrDeviceInput;
};

export type QueryVrDeviceAppDownloadArgs = {
  vrDeviceAppDownload: VrDeviceAppDownloadInput;
};

export type QueryVrDevicePlaylistTemplatesArgs = {
  input: VrDevicePlaylistsTemplateInput;
};

export type QueryVrDevicesPaginatedArgs = {
  filter?: Maybe<VrDeviceFilterInput>;
  filterBy?: Maybe<VrDeviceFilterByInput>;
  orderBy?: Maybe<VrDeviceOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QueryVrDevicesArgs = {
  companyIdOptionalInput?: Maybe<CompanyIdOptionalInput>;
};

export type QueryApplicationsArgs = {
  filter?: Maybe<ApplicationFilterInput>;
  filterBy?: Maybe<ApplicationFilterByInput>;
  orderBy?: Maybe<ApplicationOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QueryApplicationArgs = {
  app: GetApplicationInputData;
};

export type QueryApplicationVersionArgs = {
  applicationVersionInput: ApplicationVersionIdInput;
};

export type QueryApplicationSceneArgs = {
  applicationSceneInput: ApplicationSceneInput;
};

export type QueryApplicationScenesArgs = {
  filter: ApplicationSceneFilterInput;
};

export type QueryApplicationSceneGroupArgs = {
  applicationSceneGroup: ApplicationSceneGroupIdInput;
};

export type QueryApplicationSceneGroupsWithMapSelectionArgs = {
  applicationSceneGroupFilter?: Maybe<ApplicationSceneGroupFilterInput>;
};

export type QueryApplicationSceneGroupsArgs = {
  filter?: Maybe<ApplicationSceneGroupFilterInput>;
  filterBy?: Maybe<ApplicationSceneGroupFilterByInput>;
  orderBy?: Maybe<ApplicationSceneGroupOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QuerySessionArgs = {
  sessionInput: SessionInput;
};

export type QuerySessionsArgs = {
  filter?: Maybe<SessionFilterInput>;
  filterBy?: Maybe<SessionFilterByInput>;
  orderBy?: Maybe<SessionOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QueryGetRolePermissionsArgs = {
  filter: RoleFilterInput;
};

export type QueryVrBoxesArgs = {
  companyIdOptionalInput?: Maybe<CompanyIdOptionalInput>;
};

export type QueryPlaylistTemplateArgs = {
  input: PlaylistTemplateQueryInput;
};

export type QueryUnassignedPlaylistTemplatesArgs = {
  input: GetUnassignedPlaylistTemplatesInput;
};

export type QueryPlaylistTemplatesArgs = {
  filter?: Maybe<PlaylistTemplateFilterInput>;
  filterBy?: Maybe<PlaylistTemplateFilterByInput>;
  orderBy?: Maybe<PlaylistTemplateOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QueryTrainingAssignmentsArgs = {
  input: TrainingAssignmentFilterInput;
};

export type QueryUserTrainingAssignmentsArgs = {
  input: GetUserTrainingsInput;
};

export type QueryUserTrainingAssignmentArgs = {
  input: GetUserTrainingInput;
};

export type QueryFoldersArgs = {
  input: GetCompanyFoldersInput;
};

export type QueryPlaylistTemplateFoldersArgs = {
  input: GetPlaylistTemplateFoldersInput;
};

export type QueryPlaylistTemplatesFoldersArgs = {
  filter?: Maybe<PlaylistTemplatesFoldersFilterInput>;
  filterBy?: Maybe<PlaylistTemplatesFoldersFilterByInput>;
  orderBy?: Maybe<PlaylistTemplatesFoldersOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
};

export type QueryFolderArgs = {
  input: GetFolderInput;
};

export type QuerySessionPlaylistItemReportsArgs = {
  filter: SessionPlaylistItemReportFilter;
};

export type QueryUserReportsArgs = {
  input: UserReportInput;
};

export type QueryUserTrainingReportsArgs = {
  input: UserTrainingReportInput;
};

export type ReactionTimeChange = {
  __typename?: 'ReactionTimeChange';
  leftFingersFirst: Scalars['Float'];
  leftArmFirst: Scalars['Float'];
  leftFingersLast: Scalars['Float'];
  leftArmLast: Scalars['Float'];
  rightFingersFirst: Scalars['Float'];
  rightArmFirst: Scalars['Float'];
  rightFingersLast: Scalars['Float'];
  rightArmLast: Scalars['Float'];
};

export type ReactionTimeSeries = {
  __typename?: 'ReactionTimeSeries';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  leftArm: Scalars['Float'];
  rightArm: Scalars['Float'];
  leftFingers: Scalars['Float'];
  rightFingers: Scalars['Float'];
};

export type RegenerateApiKeyInput = {
  apiKeyId: Scalars['String'];
};

export type RegisterDeviceInput = {
  companyId: Scalars['ID'];
  registrationCode: Scalars['String'];
  color: Scalars['String'];
  name: Scalars['String'];
};

export type RegistrationEvent = {
  __typename?: 'RegistrationEvent';
  pairingId: Scalars['String'];
  command: Scalars['String'];
};

export type RejectPersonalDataInput = {
  rejectReason: Scalars['String'];
};

export type RemoveUserFromSessionInput = {
  sessionId: Scalars['ID'];
};

export type RemoveUserGroupInput = {
  id: Scalars['ID'];
};

export type ResetPasswordInput = {
  userId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
};

export type RoleFilterInput = {
  roles: Array<UserRole>;
};

export type RolePermissions = {
  __typename?: 'RolePermissions';
  role: UserRole;
  permissions: Array<UserPermission>;
};

export enum SaveApplicationErrorCode {
  PACKAGE_NOT_UNIQUE = 'PACKAGE_NOT_UNIQUE',
  PACKAGE_MISMATCH = 'PACKAGE_MISMATCH',
  PACKAGE_INCOMPLETE = 'PACKAGE_INCOMPLETE',
  APK_FILE_NOT_FOUND = 'APK_FILE_NOT_FOUND',
  VERSION_PUBLISHED = 'VERSION_PUBLISHED',
  VERSION_NOT_FOUND = 'VERSION_NOT_FOUND',
}

export type SaveApplicationErrors = {
  __typename?: 'SaveApplicationErrors';
  code: SaveApplicationErrorCode;
  message: Scalars['String'];
};

export type SceneParameter = {
  __typename?: 'SceneParameter';
  id: Scalars['ID'];
  name: Scalars['String'];
  name_t: Scalars['String'];
  required: Scalars['Boolean'];
  filtered: Scalars['Boolean'];
  values: Array<SceneParameterValue>;
  ordinal?: Maybe<Scalars['Float']>;
};

export type SceneParameterValue = {
  __typename?: 'SceneParameterValue';
  id: Scalars['ID'];
  name: Scalars['String'];
  name_t: Scalars['String'];
  enabled: Scalars['Boolean'];
  ordinal?: Maybe<Scalars['Float']>;
};

export type SendHelpFaqResultInput = {
  helpFaqId: Scalars['ID'];
  result: Array<HelpFaqResultInput>;
};

export enum SeriesAggregationInterval {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  vrDevice: VrDevice;
  user?: Maybe<User>;
  /** Timestamp of session start */
  startedAt: Scalars['DateTime'];
  /** Timestamp of session end. If null session is still active */
  endedAt?: Maybe<Scalars['DateTime']>;
  /** Duration of session is seconds. */
  duration?: Maybe<Scalars['Float']>;
  playlist: Array<SessionPlaylistItem>;
};

export type SessionConnection = {
  __typename?: 'SessionConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<SessionEdge>>;
  nodes?: Maybe<Array<Session>>;
};

export type SessionEdge = {
  __typename?: 'SessionEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Session;
};

export type SessionEnded = {
  __typename?: 'SessionEnded';
  sessionId: Scalars['ID'];
  session: Session;
};

export type SessionFilterByInput = {
  field: SessionFilterFiled;
  query: Scalars['String'];
};

/** Properties by which session connections can be filtered */
export enum SessionFilterFiled {
  ID = 'ID',
}

export type SessionFilterInput = {
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  vrDeviceId?: Maybe<Scalars['String']>;
};

export type SessionIdInput = {
  sessionId: Scalars['ID'];
};

export type SessionInput = {
  sessionId: Scalars['ID'];
};

export type SessionOrderByInput = {
  direction: OrderDirection;
  field: SessionOrderField;
};

/** Properties by which session connections can be ordered. Default order is STARTED_AT ASC */
export enum SessionOrderField {
  ID = 'ID',
  STARTED_AT = 'STARTED_AT',
}

export type SessionPlaylistItem = {
  __typename?: 'SessionPlaylistItem';
  id: Scalars['ID'];
  scene: ApplicationScene;
  ordinal: Scalars['Int'];
  startedAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  params: Array<SessionPlaylistParamModel>;
};

export type SessionPlaylistItemReport = {
  __typename?: 'SessionPlaylistItemReport';
  id: Scalars['ID'];
  sessionId: Scalars['String'];
  companyId: Scalars['Int'];
  company: Company;
  countTowardsCompanies: Array<Scalars['String']>;
  vrDeviceId: Scalars['String'];
  vrDevice: VrDevice;
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  launchedByUserId?: Maybe<Scalars['Int']>;
  launchedByUser?: Maybe<User>;
  sceneId: Scalars['Int'];
  scene: ApplicationScene;
  startedAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  durationMs: Scalars['Float'];
  completed: Scalars['Boolean'];
  launchParams?: Maybe<Scalars['String']>;
  reportData?: Maybe<Scalars['String']>;
  reportDataVersion?: Maybe<Scalars['String']>;
};

export type SessionPlaylistItemReportFilter = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  companyIds?: Maybe<Array<Scalars['ID']>>;
  userIds?: Maybe<Array<Scalars['ID']>>;
  sceneIds?: Maybe<Array<Scalars['ID']>>;
};

export type SessionPlaylistParamModel = {
  __typename?: 'SessionPlaylistParamModel';
  paramId: Scalars['ID'];
  name: Scalars['String'];
  name_t: Scalars['String'];
  valueId?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
  value_t: Scalars['String'];
};

export type SessionStarted = {
  __typename?: 'SessionStarted';
  session: Session;
};

export type SetSceneParameterValueInput = {
  parameterId: Scalars['ID'];
  valueId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export enum StreamType {
  MEDICAL = 'MEDICAL',
  TRAINING = 'TRAINING',
  SALES = 'SALES',
  SCHOOL = 'SCHOOL',
}

export type Subscription = {
  __typename?: 'Subscription';
  vrDevicesChanges: VrDevicesStatusesPayload;
  vrDeviceCommand: VrDeviceCommandPayload;
  webRtsAnswer: WebRtcSessionDescription;
  webRtcDeviceCandidate: WebRtcCandidate;
  sessionChange: Session;
  activeSessions: ActiveSessionsPayload;
};

export type SubscriptionVrDevicesChangesArgs = {
  companyIdOptionalInput?: Maybe<CompanyIdOptionalInput>;
};

export type SubscriptionVrDeviceCommandArgs = {
  deviceId: Scalars['ID'];
};

export type SubscriptionWebRtsAnswerArgs = {
  sessionId: Scalars['String'];
};

export type SubscriptionWebRtcDeviceCandidateArgs = {
  sessionId: Scalars['String'];
};

export type SubscriptionSessionChangeArgs = {
  input?: Maybe<SessionIdInput>;
};

export type SubscriptionActiveSessionsArgs = {
  activeSessionsInput?: Maybe<ActiveSessionsInput>;
};

export type SubsidiaryInput = {
  filter?: Maybe<CompanyFilterInput>;
  parentCompanyId?: Maybe<Scalars['ID']>;
};

export type SuspendUsersInput = {
  userIds: Array<Scalars['ID']>;
};

export type TrainingAssignment = {
  __typename?: 'TrainingAssignment';
  id: Scalars['ID'];
  playlistTemplateId: Scalars['ID'];
  validForDays: Scalars['Int'];
  gracePeriodDays: Scalars['Int'];
  userId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  userGroupId?: Maybe<Scalars['ID']>;
  userGroup?: Maybe<UserGroup>;
};

export type TrainingAssignmentEditInput = {
  validForDays: Scalars['Int'];
  gracePeriodDays: Scalars['Int'];
  validTo?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type TrainingAssignmentFilterInput = {
  companyId: Scalars['ID'];
  playlistTemplateIds?: Maybe<Array<Scalars['ID']>>;
  userIds?: Maybe<Array<Scalars['ID']>>;
  userGroupIds?: Maybe<Scalars['ID']>;
};

export type TrainingAssignmentRemoveInput = {
  trainingAssignmentIds: Array<Scalars['ID']>;
};

export type TrainingAssignmentUserGroupsInput = {
  validForDays: Scalars['Int'];
  gracePeriodDays: Scalars['Int'];
  validTo?: Maybe<Scalars['DateTime']>;
  playlistTemplateId: Scalars['ID'];
  userGroupIds: Array<Scalars['ID']>;
};

export type TrainingAssignmentUsersInput = {
  validForDays: Scalars['Int'];
  gracePeriodDays: Scalars['Int'];
  validTo?: Maybe<Scalars['DateTime']>;
  playlistTemplateId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type TrainingAssignmentsUserInput = {
  validForDays: Scalars['Int'];
  gracePeriodDays: Scalars['Int'];
  validTo?: Maybe<Scalars['DateTime']>;
  playlistTemplateIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type TrainingReportRun = {
  __typename?: 'TrainingReportRun';
  reportId: Scalars['ID'];
  date: Scalars['DateTime'];
  sceneId: Scalars['String'];
  sceneName: Scalars['String'];
};

export type UpdateAppListCommand = {
  __typename?: 'UpdateAppListCommand';
  appCategories?: Maybe<Array<Scalars['String']>>;
  appPackages?: Maybe<Array<Scalars['String']>>;
  command: Scalars['String'];
};

export type UpdateVrDeviceAppsInput = {
  installedApps?: Maybe<Array<VrDeviceApp>>;
};

export type UpdateVrDeviceStatusInput = {
  battery: Scalars['Float'];
  runningPackage?: Maybe<Scalars['String']>;
  updateInProgress?: Maybe<Scalars['Boolean']>;
};

export type UpperLimbActivityChange = {
  __typename?: 'UpperLimbActivityChange';
  leftActiveMax: Scalars['Float'];
  leftActiveFirst: Scalars['Float'];
  leftTotalFirst: Scalars['Float'];
  leftActiveLast: Scalars['Float'];
  leftTotalLast: Scalars['Float'];
  rightActiveMax: Scalars['Float'];
  rightActiveFirst: Scalars['Float'];
  rightTotalFirst: Scalars['Float'];
  rightActiveLast: Scalars['Float'];
  rightTotalLast: Scalars['Float'];
};

export type UpperLimbActivitySeries = {
  __typename?: 'UpperLimbActivitySeries';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  leftActive: Scalars['Float'];
  leftTotal: Scalars['Float'];
  rightActive: Scalars['Float'];
  rightTotal: Scalars['Float'];
};

export type UpperLimbMovement = {
  __typename?: 'UpperLimbMovement';
  key: Scalars['String'];
  sceneId: Scalars['String'];
  sceneName: Scalars['String'];
  left: Array<MovementVector>;
  right: Array<MovementVector>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  loginType?: Maybe<LoginType>;
  username?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  role: UserRole;
  diagnosis?: Maybe<UserDiagnosis>;
  permissions: Array<UserPermission>;
  /** language code according to ISO_639-1 */
  languageCode: Scalars['String'];
  status: UserStatus;
  companyId?: Maybe<Scalars['Float']>;
  subsidiaries: Array<Scalars['Float']>;
  activeSession?: Maybe<Session>;
  sessionCumulativeStatistic?: Maybe<UserSessionCumulativeStatistic>;
  note?: Maybe<Scalars['String']>;
  groups: Array<UserGroup>;
  trainingCount: Scalars['Int'];
  trainingCompletion?: Maybe<UserTrainingCompletionAgregated>;
  hasPassword: Scalars['Boolean'];
};

export type UserAddedToSession = {
  __typename?: 'UserAddedToSession';
  session: Session;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<UserEdge>>;
  nodes?: Maybe<Array<User>>;
};

export type UserDiagnosis = {
  __typename?: 'UserDiagnosis';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: User;
};

export type UserFilterByInput = {
  field: UserFilterField;
  query: Scalars['String'];
};

/** Properties by which user connections can be filtered */
export enum UserFilterField {
  LAST_NAME = 'LAST_NAME',
  FIRST_NAME = 'FIRST_NAME',
  EMAIL = 'EMAIL',
  FULLTEXT = 'FULLTEXT',
}

export type UserFilterInput = {
  role?: Maybe<Array<UserRole>>;
  status?: Maybe<UserStatus>;
  trainingPastDue?: Maybe<Scalars['Boolean']>;
  userGroupIds?: Maybe<Array<Scalars['String']>>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserGroupAssignmentInput = {
  userId: Scalars['ID'];
  userGroupId: Scalars['ID'];
};

export type UserInput = {
  /** If not provided, signed in user will be returned */
  userId?: Maybe<Scalars['ID']>;
};

export type UserOrderByInput = {
  direction: OrderDirection;
  field: UserOrderField;
};

/** Properties by which user connections can be ordered. Default order is NAME ASC */
export enum UserOrderField {
  ID = 'ID',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  CREATED_AT = 'CREATED_AT',
  LAST_SESSION = 'LAST_SESSION',
}

export enum UserPermission {
  DEBUG = 'DEBUG',
  SWITCH_COMPANIES = 'SWITCH_COMPANIES',
  LOGIN_ADMIN = 'LOGIN_ADMIN',
  LOGIN_CLIENT = 'LOGIN_CLIENT',
  READ_SCENE_GROUPS = 'READ_SCENE_GROUPS',
  MANAGE_SCENE_GROUPS = 'MANAGE_SCENE_GROUPS',
  READ_SCENES = 'READ_SCENES',
  MANAGE_SCENES = 'MANAGE_SCENES',
  MANAGE_APPLICATIONS = 'MANAGE_APPLICATIONS',
  MANAGE_COMPANIES = 'MANAGE_COMPANIES',
  MANAGE_SUBSIDIARIES = 'MANAGE_SUBSIDIARIES',
  CREATE_FEEDBACK = 'CREATE_FEEDBACK',
  READ_MANUALS = 'READ_MANUALS',
  MANAGE_MANUALS = 'MANAGE_MANUALS',
  MANAGE_COMPANY_SESSIONS = 'MANAGE_COMPANY_SESSIONS',
  MANAGE_MY_SESSIONS = 'MANAGE_MY_SESSIONS',
  UPDATE_MY_LANGUAGE = 'UPDATE_MY_LANGUAGE',
  UPDATE_MY_PASSWORD = 'UPDATE_MY_PASSWORD',
  UPDATE_COMPANY_USER_PASSWORD = 'UPDATE_COMPANY_USER_PASSWORD',
  READ_MY_USERDATA = 'READ_MY_USERDATA',
  UPDATE_COMPANY_USER_STATUS = 'UPDATE_COMPANY_USER_STATUS',
  UPDATE_COMPANY_PATIENT_STATUS = 'UPDATE_COMPANY_PATIENT_STATUS',
  MANAGE_COMPANY_USERS = 'MANAGE_COMPANY_USERS',
  VIEW_COMPANY_USERS = 'VIEW_COMPANY_USERS',
  MANAGE_COMPANY_PATIENTS = 'MANAGE_COMPANY_PATIENTS',
  READ_COMPANY_VR_DEVICES = 'READ_COMPANY_VR_DEVICES',
  READ_ALL_VR_DEVICES = 'READ_ALL_VR_DEVICES',
  MANAGE_VR_DEVICE_OS_UPDATES = 'MANAGE_VR_DEVICE_OS_UPDATES',
  DISPLAY_COMPANY_VR_DEVICES = 'DISPLAY_COMPANY_VR_DEVICES',
  UPDATE_COMPANY_VR_DEVICES = 'UPDATE_COMPANY_VR_DEVICES',
  MANAGE_COMPANY_VR_DEVICES = 'MANAGE_COMPANY_VR_DEVICES',
  READ_COMPANY_VR_BOXES = 'READ_COMPANY_VR_BOXES',
  MANAGE_COMPANY_VR_BOXES = 'MANAGE_COMPANY_VR_BOXES',
  READ_MY_PLAYLIST_TEMPLATES = 'READ_MY_PLAYLIST_TEMPLATES',
  RESTART_DEVICE = 'RESTART_DEVICE',
  MANAGE_MY_PLAYLIST_TEMPLATES = 'MANAGE_MY_PLAYLIST_TEMPLATES',
  MANAGE_OTHERS_PLAYLIST_TEMPLATES = 'MANAGE_OTHERS_PLAYLIST_TEMPLATES',
  MANAGE_TRAINING_ASSIGNMENTS = 'MANAGE_TRAINING_ASSIGNMENTS',
  VIEW_TRAINING_ASSIGNMENTS = 'VIEW_TRAINING_ASSIGNMENTS',
  VIEW_MY_TRAINING_ASSIGNMENTS = 'VIEW_MY_TRAINING_ASSIGNMENTS',
  UPDATE_APPS_ON_VR_DEVICE = 'UPDATE_APPS_ON_VR_DEVICE',
  VIEW_REPORTS = 'VIEW_REPORTS',
  VIEW_MEDICAL_REPORTS = 'VIEW_MEDICAL_REPORTS',
  VIEW_MY_MEDICAL_REPORTS = 'VIEW_MY_MEDICAL_REPORTS',
  VIEW_TRAINING_REPORTS = 'VIEW_TRAINING_REPORTS',
  VIEW_MY_TRAINING_REPORTS = 'VIEW_MY_TRAINING_REPORTS',
  UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO',
  DEVICES_ROUTE = 'DEVICES_ROUTE',
  PLAYLIST_ROUTE = 'PLAYLIST_ROUTE',
  SALES_ROUTE = 'SALES_ROUTE',
  VIEW_LIVE_STREAM = 'VIEW_LIVE_STREAM',
  LAUNCH_ANNONYMOUS_PLAYLIST = 'LAUNCH_ANNONYMOUS_PLAYLIST',
  INSTALLER_API_ACCESS = 'INSTALLER_API_ACCESS',
  EXTERNAL_API_ACCESS = 'EXTERNAL_API_ACCESS',
  MANAGE_COMPANY_API_KEYS = 'MANAGE_COMPANY_API_KEYS',
  MANAGE_PLAYLIST_FOLDERS = 'MANAGE_PLAYLIST_FOLDERS',
  READ_PLAYLIST_FOLDERS = 'READ_PLAYLIST_FOLDERS',
  PLAYLIST_FOLDERS_ASSIGNMENTS = 'PLAYLIST_FOLDERS_ASSIGNMENTS',
  CI_CD_UPDATE_APPS_ON_VR_DEVICE = 'CI_CD_UPDATE_APPS_ON_VR_DEVICE',
}

export type UserRemovedFromSession = {
  __typename?: 'UserRemovedFromSession';
  sessionId: Scalars['ID'];
  session: Session;
};

export type UserReport = {
  __typename?: 'UserReport';
  id: Scalars['ID'];
  sessionCount: Scalars['Int'];
  gameTimeSeconds: Scalars['Float'];
  runTimeSeconds: Scalars['Float'];
  gameTimeProgressSeries: Array<UserReportProgress>;
  gameTimeLastSession?: Maybe<UserReportProgress>;
  shouldersRangeSeries: Array<UserReportShouldersRange>;
  shouldersRangeChange?: Maybe<UserReportRangeChange>;
  armsFlexSeries: Array<UserReportArmsFlex>;
  armsFlexChange?: Maybe<UserReportRangeChange>;
  armsAbductionSeries: Array<UserReportArmsAbduction>;
  armsAbductionChange?: Maybe<UserReportRangeChange>;
  fineMotoricsSeries: Array<UserReportFineMotorics>;
  upperLimbMovementIntervals: Array<Scalars['DateTime']>;
  upperLimbMovementSeries: Array<UpperLimbMovement>;
  headMovementIntervals: Array<Scalars['DateTime']>;
  headMovementSeries: Array<HeadMovement>;
  headRotationRanges: HeadRotation;
  headRotationSeries: Array<HeadRotationSeries>;
  reactionTimeSeries: Array<ReactionTimeSeries>;
  reactionTimeChange?: Maybe<ReactionTimeChange>;
  upperLimbActivityChange: UpperLimbActivityChange;
  upperLimbActivitySeries: Array<UpperLimbActivitySeries>;
  scene: ApplicationScene;
};

export type UserReportArmsAbduction = {
  __typename?: 'UserReportArmsAbduction';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  maxArmAbductionRight: Scalars['Float'];
  maxArmAbductionLeft: Scalars['Float'];
};

export type UserReportArmsFlex = {
  __typename?: 'UserReportArmsFlex';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  maxArmFlexRight: Scalars['Float'];
  maxArmFlexLeft: Scalars['Float'];
};

export type UserReportFineMotorics = {
  __typename?: 'UserReportFineMotorics';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  leftTotal: Scalars['Float'];
  leftPosition1: Scalars['Float'];
  leftPosition2: Scalars['Float'];
  leftPosition3: Scalars['Float'];
  leftPosition4: Scalars['Float'];
  leftPosition5: Scalars['Float'];
  rightTotal: Scalars['Float'];
  rightPosition1: Scalars['Float'];
  rightPosition2: Scalars['Float'];
  rightPosition3: Scalars['Float'];
  rightPosition4: Scalars['Float'];
  rightPosition5: Scalars['Float'];
};

export type UserReportInput = {
  userId: Scalars['ID'];
  seriesInterval?: Maybe<SeriesAggregationInterval>;
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
  /** Timezone in as Europe/Prague or offset +02:00 */
  timezone?: Maybe<Scalars['String']>;
};

export type UserReportProgress = {
  __typename?: 'UserReportProgress';
  key: Scalars['String'];
  gameTimeSeconds: Scalars['Float'];
  runTimeSeconds: Scalars['Float'];
  docCount: Scalars['Int'];
};

export type UserReportRangeChange = {
  __typename?: 'UserReportRangeChange';
  leftPercent: Scalars['Float'];
  leftChange: Scalars['Float'];
  leftFirstMeasured: Scalars['Float'];
  leftLastMeasured: Scalars['Float'];
  rightPercent: Scalars['Float'];
  rightChange: Scalars['Float'];
  rightFirstMeasured: Scalars['Float'];
  rightLastMeasured: Scalars['Float'];
};

export type UserReportShouldersRange = {
  __typename?: 'UserReportShouldersRange';
  key: Scalars['String'];
  docCount: Scalars['Int'];
  maxShoulderRight: Scalars['Float'];
  maxShoulderLeft: Scalars['Float'];
};

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  TRAINING_ADMIN = 'TRAINING_ADMIN',
  TRAINING_LECTURER = 'TRAINING_LECTURER',
  TRAINING_LECTURER_ASSISTANT = 'TRAINING_LECTURER_ASSISTANT',
  TRAINING_STUDENT = 'TRAINING_STUDENT',
  TRAINING_STUDENT_BASIC = 'TRAINING_STUDENT_BASIC',
  MEDICAL_ADMIN = 'MEDICAL_ADMIN',
  MEDICAL_LECTURER = 'MEDICAL_LECTURER',
  MEDICAL_LECTURER_ASSISTANT = 'MEDICAL_LECTURER_ASSISTANT',
  MEDICAL_PATIENT = 'MEDICAL_PATIENT',
  SALES_ADMIN = 'SALES_ADMIN',
  SALES_SALESPERSON = 'SALES_SALESPERSON',
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
  SCHOOL_TEACHER = 'SCHOOL_TEACHER',
  NONE = 'NONE',
  API_ACCESS = 'API_ACCESS',
  CI_CD = 'CI_CD',
}

export type UserSessionCumulativeStatistic = {
  __typename?: 'UserSessionCumulativeStatistic';
  id: Scalars['ID'];
  /** Number of finished sessions */
  numberOfSessions: Scalars['Float'];
  /** Sum of sessions duration */
  allTimeSpent: Scalars['Float'];
  /** Time stamp of start of last session */
  lastSession: Scalars['DateTime'];
  /** Most used scene */
  mostUsedScene?: Maybe<ApplicationScene>;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export type UserTrainingAssignment = {
  __typename?: 'UserTrainingAssignment';
  id: Scalars['ID'];
  trainingAssignmentId: Scalars['ID'];
  playlistTemplate: PlaylistTemplate;
  validForDays: Scalars['Int'];
  gracePeriodDays: Scalars['Int'];
  user: User;
  userGroup?: Maybe<UserGroup>;
  assignedAt: Scalars['DateTime'];
  completion: UserTrainingCompletionStatus;
  certificate?: Maybe<UserTrainingCertificate>;
};

export type UserTrainingCertificate = {
  __typename?: 'UserTrainingCertificate';
  id: Scalars['ID'];
  trainingAssignmentId: Scalars['Int'];
  url: Scalars['String'];
};

export type UserTrainingCompletionAgregated = {
  __typename?: 'UserTrainingCompletionAgregated';
  id: Scalars['ID'];
  dueDate: Scalars['DateTime'];
  completed: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['DateTime']>;
};

export type UserTrainingCompletionStatus = {
  __typename?: 'UserTrainingCompletionStatus';
  id: Scalars['ID'];
  itemsTotal: Scalars['Int'];
  itemsCompleted: Scalars['Int'];
  completedItems: Array<UserTrainingcompletionItemStatus>;
  dueDate: Scalars['DateTime'];
};

export type UserTrainingDetailReport = {
  __typename?: 'UserTrainingDetailReport';
  id: Scalars['ID'];
  sceneId: Scalars['String'];
  sceneName: Scalars['String'];
  result?: Maybe<Scalars['String']>;
  sequences: Array<UserTrainingSequenceResult>;
};

export type UserTrainingReport = {
  __typename?: 'UserTrainingReport';
  id: Scalars['ID'];
  trainingReports: Array<TrainingReportRun>;
  trainingDetail?: Maybe<UserTrainingDetailReport>;
};

export type UserTrainingReportTrainingDetailArgs = {
  reportId: Scalars['ID'];
};

export type UserTrainingReportInput = {
  userId: Scalars['ID'];
};

export type UserTrainingSequenceResult = {
  __typename?: 'UserTrainingSequenceResult';
  name?: Maybe<Scalars['String']>;
  onTime?: Maybe<Scalars['String']>;
  correctCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  mistakes: Array<Scalars['String']>;
};

export type UserTrainingcompletionItemStatus = {
  __typename?: 'UserTrainingcompletionItemStatus';
  id: Scalars['ID'];
  sceneId: Scalars['String'];
  completionDate: Scalars['DateTime'];
};

export type UsersFilterInput = {
  /** SuperAdmin must provide this value!. For admin this value is ignored! */
  companyId?: Maybe<Scalars['ID']>;
  filter?: Maybe<UserFilterInput>;
};

export type VrBox = {
  __typename?: 'VrBox';
  id: Scalars['ID'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  companyId: Scalars['Int'];
};

export type VrBoxIdInput = {
  id: Scalars['ID'];
};

export type VrDevice = {
  __typename?: 'VrDevice';
  id: Scalars['ID'];
  /** Identifies the date and time when the object was created. */
  createdAt: Scalars['DateTime'];
  /** Identifies the date and time when the object was last updated. */
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  color: Scalars['String'];
  status: VrDeviceStatus;
  session?: Maybe<Session>;
  applications: Array<Application>;
  companyName?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  scenes: Array<LaunchableApplicationScene>;
  upToDate: Scalars['Boolean'];
  vrBox?: Maybe<VrBox>;
  vrBoxId?: Maybe<Scalars['String']>;
  deviceOwner?: Maybe<Scalars['Boolean']>;
  osVersion?: Maybe<Scalars['String']>;
  osUpdatesEnabled: Scalars['Boolean'];
};

export type VrDeviceApp = {
  package: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type VrDeviceAppDownloadFinishedInput = {
  package: Scalars['String'];
};

export type VrDeviceAppDownloadInput = {
  package: Scalars['String'];
};

export type VrDeviceAppDownloadPayload = {
  __typename?: 'VrDeviceAppDownloadPayload';
  url: Scalars['String'];
  sha1: Scalars['String'];
};

export type VrDeviceAppsUpdated = {
  __typename?: 'VrDeviceAppsUpdated';
  type: Scalars['String'];
  device: VrDevice;
};

export type VrDeviceCommandPayload =
  | RegistrationEvent
  | UpdateAppListCommand
  | EmptyEvent
  | LaunchAppCommand
  | LaunchUrlCommand
  | WebRtcOfferCommand
  | WebRtcCandidateCommand
  | CheckInstalledAppsCommand;

export type VrDeviceConnection = {
  __typename?: 'VrDeviceConnection';
  /** Cursors for traditional page based pagination */
  pageCursors: PageCursors;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges?: Maybe<Array<VrDeviceEdge>>;
  nodes?: Maybe<Array<VrDevice>>;
};

export type VrDeviceEdge = {
  __typename?: 'VrDeviceEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: VrDevice;
};

export type VrDeviceFilterByInput = {
  field: VrDeviceFilterField;
  query: Scalars['String'];
};

/** Properties by which vrDevice connections can be filtered */
export enum VrDeviceFilterField {
  OS_VERSION = 'OS_VERSION',
  NAME = 'NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  ID = 'ID',
}

export type VrDeviceFilterInput = {
  companyIds?: Maybe<Array<Scalars['String']>>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type VrDeviceIdInput = {
  vrDeviceId: Scalars['ID'];
};

export type VrDeviceInput = {
  id: Scalars['ID'];
};

export type VrDeviceLaunchConfirmedInput = {
  playlistItemId: Scalars['Int'];
};

export type VrDeviceLaunchPlaylistsTemplateInput = {
  playlistTemplateId: Scalars['ID'];
  sceneOrdinal?: Maybe<Scalars['Int']>;
};

export type VrDeviceLoginResponse = {
  __typename?: 'VrDeviceLoginResponse';
  accessKey?: Maybe<Scalars['String']>;
  osUpdatesEnabled?: Maybe<Scalars['Boolean']>;
};

export type VrDeviceOrderByInput = {
  direction: OrderDirection;
  field: VrDeviceOrderField;
};

/** Properties by which vr device connections can be ordered. Default order is ID ASC */
export enum VrDeviceOrderField {
  OS_VERSION = 'OS_VERSION',
  NAME = 'NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  ID = 'ID',
}

export type VrDevicePlaylistsTemplateInput = {
  language: Scalars['String'];
};

export type VrDeviceRegistered = {
  __typename?: 'VrDeviceRegistered';
  device: VrDevice;
};

export type VrDeviceStatus = {
  __typename?: 'VrDeviceStatus';
  battery?: Maybe<Scalars['Float']>;
  state: DeviceState;
  updateInProgress: Scalars['Boolean'];
};

export type VrDeviceStatusUpdateEvent = {
  __typename?: 'VrDeviceStatusUpdateEvent';
  battery?: Maybe<Scalars['Float']>;
  state: DeviceState;
  updateInProgress: Scalars['Boolean'];
  deviceId: Scalars['String'];
  companyId: Scalars['ID'];
};

export type VrDeviceSysUpdateEnabledInput = {
  enabled: Scalars['Boolean'];
  vrDeviceId?: Maybe<Scalars['String']>;
};

export type VrDeviceUnRegistered = {
  __typename?: 'VrDeviceUnRegistered';
  type: Scalars['String'];
  device: VrDevice;
};

export type VrDevicesStatusesPayload =
  | VrDeviceStatusUpdateEvent
  | VrDeviceRegistered
  | VrDeviceUnRegistered
  | DeviceSessionStarted
  | DeviceSessionEnded
  | VrDeviceAppsUpdated;

export type WebRtcAsnwerSessionDescriptionInput = {
  type: Scalars['String'];
  sdp: Scalars['String'];
  sessionId: Scalars['String'];
};

export type WebRtcCandidate = {
  __typename?: 'WebRtcCandidate';
  sdpMLineIndex?: Maybe<Scalars['Float']>;
  sdpMid?: Maybe<Scalars['String']>;
  candidate: Scalars['String'];
};

export type WebRtcCandidateCommand = {
  __typename?: 'WebRtcCandidateCommand';
  sessionId: Scalars['String'];
  candidate: WebRtcCandidate;
  command: Scalars['String'];
  workaroundEnabled?: Maybe<Scalars['Boolean']>;
};

export type WebRtcCandidateInput = {
  candidate: Scalars['String'];
  sdpMLineIndex?: Maybe<Scalars['Int']>;
  sdpMid?: Maybe<Scalars['String']>;
};

export type WebRtcCandidateMessageInput = {
  deviceId?: Maybe<Scalars['ID']>;
  sessionId: Scalars['String'];
  source: Scalars['String'];
  candidate: WebRtcCandidateInput;
};

export type WebRtcNewOfferInput = {
  deviceId: Scalars['ID'];
  offer: WebRtcSessionDescriptionInput;
};

export type WebRtcOfferCommand = {
  __typename?: 'WebRtcOfferCommand';
  sessionId: Scalars['String'];
  iceServerConfig: IceServerConfig;
  type: Scalars['String'];
  sdp: Scalars['String'];
  command: Scalars['String'];
  workaroundEnabled?: Maybe<Scalars['Boolean']>;
};

export type WebRtcSessionDescription = {
  __typename?: 'WebRtcSessionDescription';
  type: Scalars['String'];
  sdp: Scalars['String'];
};

export type WebRtcSessionDescriptionInput = {
  type: Scalars['String'];
  sdp: Scalars['String'];
};

export type GetUsersForSelectQueryVariables = Exact<{
  filter?: Maybe<UsersFilterInput>;
  filterBy?: Maybe<UserFilterByInput>;
}>;

export type GetUsersForSelectQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    nodes?: Maybe<
      Array<{
        __typename?: 'User';
        id: string;
        lastName?: Maybe<string>;
        activeSession?: Maybe<{ __typename?: 'Session'; id: string }>;
      }>
    >;
  };
};

export type SubmitFeedbackMutationVariables = Exact<{
  feedbackInput: FeedbackInput;
  files?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;

export type SubmitFeedbackMutation = {
  __typename?: 'Mutation';
  submitFeedback: boolean;
};

export type VrDeviceSceneGroupFragmentFragment = {
  __typename?: 'ApplicationSceneGroup';
  id: string;
  name: string;
  enabled: boolean;
  status: ApplicationSceneGroupStatus;
  mapSelectionEnabled: boolean;
  mapSelectionId?: Maybe<number>;
};

export type GetVrDeviceScenesQueryVariables = Exact<{
  vrDeviceId: Scalars['ID'];
}>;

export type GetVrDeviceScenesQuery = {
  __typename?: 'Query';
  vrDevice: {
    __typename?: 'VrDevice';
    id: string;
    scenes: Array<{
      __typename?: 'LaunchableApplicationScene';
      scene: {
        __typename?: 'ApplicationScene';
        id: string;
        name_t: string;
        description: string;
        enabled: boolean;
        imageUrl?: Maybe<string>;
        parameters: Array<{
          __typename?: 'SceneParameter';
          id: string;
          name: string;
          name_t: string;
          required: boolean;
          filtered: boolean;
          values: Array<{
            __typename?: 'SceneParameterValue';
            id: string;
            name: string;
            name_t: string;
            enabled: boolean;
            ordinal?: Maybe<number>;
          }>;
        }>;
        applicationSceneGroups?: Maybe<
          Array<
            {
              __typename?: 'ApplicationSceneGroup';
            } & VrDeviceSceneGroupFragmentFragment
          >
        >;
      };
      applicationVersion: {
        __typename?: 'ApplicationVersion';
        id: string;
        version: string;
        application: { __typename?: 'Application'; id: string; name: string };
      };
    }>;
  };
};

export type CreateSessionMutationMutationVariables = Exact<{
  createSessionInput: CreateSessionInput;
}>;

export type CreateSessionMutationMutation = {
  __typename?: 'Mutation';
  createSession: Array<{
    __typename?: 'Session';
    id: string;
    vrDevice: { __typename?: 'VrDevice'; id: string };
  }>;
};

export type GetLastLaunchableSessionsQueryVariables = Exact<{
  userId: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
}>;

export type GetLastLaunchableSessionsQuery = {
  __typename?: 'Query';
  sessions: {
    __typename?: 'SessionConnection';
    nodes?: Maybe<
      Array<{
        __typename?: 'Session';
        id: string;
        playlist: Array<{
          __typename?: 'SessionPlaylistItem';
          id: string;
          ordinal: number;
          startedAt?: Maybe<string>;
          endedAt?: Maybe<string>;
          scene: {
            __typename?: 'ApplicationScene';
            id: string;
            name_t: string;
          };
          params: Array<{
            __typename?: 'SessionPlaylistParamModel';
            paramId: string;
            name: string;
            name_t: string;
            valueId?: Maybe<string>;
            value: string;
            value_t: string;
          }>;
        }>;
      }>
    >;
  };
};

export type SessionFragmentFragment = {
  __typename?: 'Session';
  id: string;
  startedAt: string;
  user?: Maybe<{
    __typename?: 'User';
    id: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
  }>;
  vrDevice: { __typename?: 'VrDevice'; id: string; name: string };
};

export type GetLiveSessionsSubscriptionSubscriptionVariables = Exact<{
  activeSessionsInput: ActiveSessionsInput;
}>;

export type GetLiveSessionsSubscriptionSubscription = {
  __typename?: 'Subscription';
  activeSessions:
    | {
        __typename?: 'ActiveSessions';
        sessions: Array<{ __typename?: 'Session' } & SessionFragmentFragment>;
      }
    | {
        __typename?: 'SessionStarted';
        session: { __typename?: 'Session' } & SessionFragmentFragment;
      }
    | { __typename?: 'SessionEnded'; sessionId: string }
    | {
        __typename?: 'UserAddedToSession';
        session: { __typename?: 'Session' } & SessionFragmentFragment;
      }
    | { __typename?: 'UserRemovedFromSession'; sessionId: string };
};

export type ApplicationFragmentFragment = {
  __typename?: 'Application';
  id: string;
  name: string;
  package: string;
  enabled: boolean;
  systemApp: boolean;
  status: ApplicationStatus;
  currentVersion?: Maybe<{
    __typename?: 'ApplicationVersion';
    id: string;
    version: string;
    publishedAt?: Maybe<string>;
    scenes: Array<{
      __typename?: 'ApplicationScene';
      id: string;
      name_t: string;
    }>;
  }>;
};

export type UploadApkMutationVariables = Exact<{
  file: Scalars['Upload'];
  input: ApkUploadChunkInfoInput;
}>;

export type UploadApkMutation = {
  __typename?: 'Mutation';
  uploadApk: {
    __typename?: 'ApkUploadResultPayload';
    fileId: string;
    name?: Maybe<string>;
    version?: Maybe<string>;
    confirmedChunk: number;
  };
};

export type GetApplicationsQueryVariables = Exact<{
  filter?: Maybe<ApplicationFilterInput>;
  filterBy?: Maybe<ApplicationFilterByInput>;
  orderBy?: Maybe<ApplicationOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetApplicationsQuery = {
  __typename?: 'Query';
  applications: {
    __typename?: 'ApplicationConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<{ __typename?: 'Application' } & ApplicationFragmentFragment>
    >;
  };
};

export type GetApplicationDetailQueryVariables = Exact<{
  app: GetApplicationInputData;
}>;

export type GetApplicationDetailQuery = {
  __typename?: 'Query';
  application: {
    __typename?: 'Application';
    status: ApplicationStatus;
    versions: Array<{
      __typename?: 'ApplicationVersionShort';
      id: string;
      version: string;
    }>;
  } & ApplicationFragmentFragment;
};

export type GetApplicationVersionQueryVariables = Exact<{
  applicationVersionId: Scalars['ID'];
}>;

export type GetApplicationVersionQuery = {
  __typename?: 'Query';
  applicationVersion: {
    __typename?: 'ApplicationVersion';
    id: string;
    version: string;
    published: boolean;
    publishedAt?: Maybe<string>;
    application: {
      __typename?: 'Application';
      id: string;
      name: string;
      enabled: boolean;
      versions: Array<{
        __typename?: 'ApplicationVersionShort';
        id: string;
        version: string;
        published: boolean;
        publishedAt?: Maybe<string>;
      }>;
      usageStatistics: {
        __typename?: 'ApplicationUsageStatistics';
        sessionCount: number;
      };
    };
    scenes: Array<
      { __typename?: 'ApplicationScene' } & ApplicationSceneFragmentFragment
    >;
  };
};

export type GetAllApplicationScenesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllApplicationScenesQuery = {
  __typename?: 'Query';
  allApplicationScenes: Array<{
    __typename?: 'ApplicationScene';
    id: string;
    applicationVersionId: number;
    name_t: string;
    enabled: boolean;
  }>;
};

export type PublishApplicationVersionMutationVariables = Exact<{
  versionId: Scalars['ID'];
}>;

export type PublishApplicationVersionMutation = {
  __typename?: 'Mutation';
  publishApplicationVersion: {
    __typename?: 'ApplicationVersion';
    id: string;
    publishedAt?: Maybe<string>;
    published: boolean;
  };
};

export type SetApplicationEnabledMutationVariables = Exact<{
  status: ApplicationSetEnabledInputData;
}>;

export type SetApplicationEnabledMutation = {
  __typename?: 'Mutation';
  setApplicationEnabled: {
    __typename?: 'Application';
    id: string;
    enabled: boolean;
  };
};

export type EditApplicationMutationVariables = Exact<{
  app: EditApplicationInputData;
}>;

export type EditApplicationMutation = {
  __typename?: 'Mutation';
  editApplication: {
    __typename?: 'ApplicationPayload';
    application?: Maybe<
      { __typename?: 'Application' } & ApplicationFragmentFragment
    >;
    errors: Array<{
      __typename?: 'SaveApplicationErrors';
      code: SaveApplicationErrorCode;
      message: string;
    }>;
  };
};

export type CreateApplicationMutationVariables = Exact<{
  app: CreateApplicationInputData;
}>;

export type CreateApplicationMutation = {
  __typename?: 'Mutation';
  createApplication: {
    __typename?: 'ApplicationPayload';
    application?: Maybe<
      { __typename?: 'Application' } & ApplicationFragmentFragment
    >;
    errors: Array<{
      __typename?: 'SaveApplicationErrors';
      code: SaveApplicationErrorCode;
      message: string;
    }>;
  };
};

export type SceneFragmentFragment = {
  __typename?: 'ApplicationScene';
  id: string;
  name: string;
  name_t: string;
  description: string;
  enabled: boolean;
  parameters: Array<{
    __typename?: 'SceneParameter';
    id: string;
    name: string;
    name_t: string;
    values: Array<{
      __typename?: 'SceneParameterValue';
      id: string;
      name: string;
      name_t: string;
      enabled: boolean;
    }>;
  }>;
  applicationSceneGroups?: Maybe<
    Array<{
      __typename?: 'ApplicationSceneGroup';
      id: string;
      name: string;
      enabled: boolean;
    }>
  >;
};

export type ApplicationSceneFragmentFragment = {
  __typename?: 'ApplicationScene';
  imageUrl?: Maybe<string>;
  manualUrl?: Maybe<string>;
  applicationVersions: Array<{
    __typename?: 'ApplicationVersionShort';
    id: string;
    version: string;
  }>;
} & SceneFragmentFragment;

export type GetApplicationSceneQueryVariables = Exact<{
  applicationSceneId: Scalars['ID'];
  applicationVersionId: Scalars['ID'];
}>;

export type GetApplicationSceneQuery = {
  __typename?: 'Query';
  applicationScene: {
    __typename?: 'ApplicationScene';
  } & ApplicationSceneFragmentFragment;
};

export type EditApplicationSceneMutationVariables = Exact<{
  scene: EditApplicationSceneInput;
  image?: Maybe<Scalars['Upload']>;
}>;

export type EditApplicationSceneMutation = {
  __typename?: 'Mutation';
  editApplicationScene: {
    __typename?: 'ApplicationScene';
  } & ApplicationSceneFragmentFragment;
};

export type UploadApplicationSceneManualMutationVariables = Exact<{
  input: ApplicationSceneInput;
  manual: Scalars['Upload'];
}>;

export type UploadApplicationSceneManualMutation = {
  __typename?: 'Mutation';
  uploadApplicationSceneManual: {
    __typename?: 'ApplicationScene';
    id: string;
    manualUrl?: Maybe<string>;
  };
};

export type ApplicationSceneGroupFragmentFragment = {
  __typename?: 'ApplicationSceneGroup';
  id: string;
  name: string;
  enabled: boolean;
  status: ApplicationSceneGroupStatus;
  currentScenes: Array<{
    __typename?: 'ApplicationScene';
    id: string;
    name_t: string;
  }>;
};

export type CreateApplicationSceneGroupMutationVariables = Exact<{
  createApplicationSceneGroup: CreateApplicationSceneGroupInput;
}>;

export type CreateApplicationSceneGroupMutation = {
  __typename?: 'Mutation';
  createApplicationSceneGroup: {
    __typename?: 'CreateApplicationSceneGroupPayload';
    applicationSceneGroup?: Maybe<
      {
        __typename?: 'ApplicationSceneGroup';
        mapSelectionEnabled: boolean;
        mapSelectionId?: Maybe<number>;
      } & ApplicationSceneGroupFragmentFragment
    >;
    errors: Array<{
      __typename?: 'CreateApplicationSceneGroupError';
      code: CrateApplicationSceneGroupErrorCode;
      message: string;
    }>;
  };
};

export type EditApplicationSceneGroupMutationVariables = Exact<{
  editApplicationSceneGroupInput: EditApplicationSceneGroupInput;
}>;

export type EditApplicationSceneGroupMutation = {
  __typename?: 'Mutation';
  editApplicationSceneGroup: {
    __typename?: 'EditApplicationSceneGroupPayload';
    applicationSceneGroup?: Maybe<
      {
        __typename?: 'ApplicationSceneGroup';
        mapSelectionEnabled: boolean;
        mapSelectionId?: Maybe<number>;
      } & ApplicationSceneGroupFragmentFragment
    >;
    errors: Array<{
      __typename?: 'CreateApplicationSceneGroupError';
      code: CrateApplicationSceneGroupErrorCode;
      message: string;
    }>;
  };
};

export type GetApplicationSceneGroupsQueryVariables = Exact<{
  filter?: Maybe<ApplicationSceneGroupFilterInput>;
  filterBy?: Maybe<ApplicationSceneGroupFilterByInput>;
  orderBy?: Maybe<ApplicationSceneGroupOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetApplicationSceneGroupsQuery = {
  __typename?: 'Query';
  applicationSceneGroups: {
    __typename?: 'ApplicationSceneGroupConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<
        {
          __typename?: 'ApplicationSceneGroup';
        } & ApplicationSceneGroupFragmentFragment
      >
    >;
  };
};

export type GetApplicationSceneGroupsMapSelectionQueryVariables = Exact<{
  filter?: Maybe<ApplicationSceneGroupFilterInput>;
}>;

export type GetApplicationSceneGroupsMapSelectionQuery = {
  __typename?: 'Query';
  applicationSceneGroupsWithMapSelection: Array<{
    __typename?: 'ApplicationSceneGroup';
    id: string;
    mapSelectionId?: Maybe<number>;
  }>;
};

export type GetApplicationSceneGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetApplicationSceneGroupQuery = {
  __typename?: 'Query';
  applicationSceneGroup: {
    __typename?: 'ApplicationSceneGroup';
    id: string;
    name: string;
    enabled: boolean;
    status: ApplicationSceneGroupStatus;
    mapSelectionEnabled: boolean;
    mapSelectionId?: Maybe<number>;
    currentScenes: Array<
      { __typename?: 'ApplicationScene' } & ApplicationSceneFragmentFragment
    >;
  };
};

export type ActivateUsersMutationVariables = Exact<{
  activateUsersInput: ActivateUsersInput;
}>;

export type ActivateUsersMutation = {
  __typename?: 'Mutation';
  activateUsers: Array<{ __typename?: 'User'; id: string; status: UserStatus }>;
};

export type CreateCompanyMutationVariables = Exact<{
  createCompany: CreateCompanyInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: {
    __typename?: 'CreateCompanyPayload';
    company?: Maybe<{ __typename?: 'Company'; id: string; name: string }>;
    errors: Array<{
      __typename?: 'CreateCompanyError';
      argument: string;
      code: CrateCompanyError;
      message: string;
    }>;
  };
};

export type EditCompanyMutationVariables = Exact<{
  editCompany: EditCompanyInput;
}>;

export type EditCompanyMutation = {
  __typename?: 'Mutation';
  editCompany: { __typename?: 'Company' } & CompanyFragmentFragment;
};

export type ChangeCompanyStatusMutationVariables = Exact<{
  status: ChangeCompanyInput;
}>;

export type ChangeCompanyStatusMutation = {
  __typename?: 'Mutation';
  changeCompanyStatus: {
    __typename?: 'Company';
    id: string;
    status: CompanyStatus;
  };
};

export type CompanyFragmentFragment = {
  __typename?: 'Company';
  id: string;
  name: string;
  note?: Maybe<string>;
  streamType: StreamType;
  status: CompanyStatus;
  parentCompanyId?: Maybe<string>;
  defaultLoginType?: Maybe<LoginType>;
  helpFaqId?: Maybe<number>;
  logoUrl?: Maybe<string>;
  hasCustomLogo: boolean;
};

export type GetDirectSubsidiariesQueryVariables = Exact<{
  subsidiaryInput: SubsidiaryInput;
}>;

export type GetDirectSubsidiariesQuery = {
  __typename?: 'Query';
  directSubsidiaries: Array<
    { __typename?: 'Company' } & CompanyFragmentFragment
  >;
};

export type GetCompaniesQueryVariables = Exact<{
  companyFilter?: Maybe<CompanyFilterInput>;
}>;

export type GetCompaniesQuery = {
  __typename?: 'Query';
  companies: Array<{ __typename?: 'Company' } & CompanyFragmentFragment>;
};

export type GetVrBoxesQueryVariables = Exact<{
  companyIdOptionalInput: CompanyIdOptionalInput;
}>;

export type GetVrBoxesQuery = {
  __typename?: 'Query';
  vrBoxes: Array<{
    __typename?: 'VrBox';
    id: string;
    name: string;
    companyId: number;
  }>;
};

export type CreateVrBoxMutationVariables = Exact<{
  vrBoxInput: CreateVrBoxInput;
}>;

export type CreateVrBoxMutation = {
  __typename?: 'Mutation';
  createVrBox: { __typename?: 'VrBox'; id: string; name: string };
};

export type UpdateCompanyLogoMutationVariables = Exact<{
  companyInput: CompanyIdInput;
  file?: Maybe<Scalars['Upload']>;
}>;

export type UpdateCompanyLogoMutation = {
  __typename?: 'Mutation';
  updateCompanyLogo: {
    __typename?: 'Company';
    id: string;
    logoUrl?: Maybe<string>;
    hasCustomLogo: boolean;
  };
};

export type EditVrBoxMutationVariables = Exact<{
  vrBoxInput: EditVrBoxInput;
}>;

export type EditVrBoxMutation = {
  __typename?: 'Mutation';
  editVrBox: { __typename?: 'VrBox'; id: string; name: string };
};

export type DeleteVrBoxMutationVariables = Exact<{
  vrBoxIdInput: VrBoxIdInput;
}>;

export type DeleteVrBoxMutation = {
  __typename?: 'Mutation';
  deleteVrBox: boolean;
};

export type GetCompanyQueryVariables = Exact<{
  companyIdInput: CompanyIdInput;
}>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    features: Array<CompanyFeature>;
    parentCompany?: Maybe<{ __typename?: 'Company'; id: string; name: string }>;
    statistics: {
      __typename?: 'CompanyStatistics';
      activeUsers: number;
      apiKeys: number;
      headsets: number;
      vrBoxes: number;
      scenes: number;
      userGroups: number;
      features: number;
    };
  } & CompanyFragmentFragment;
};

export type AssignableApplicationScenesQueryVariables = Exact<{
  companyIdInput: CompanyIdInput;
}>;

export type AssignableApplicationScenesQuery = {
  __typename?: 'Query';
  assignableApplicationScenes: Array<{
    __typename?: 'ApplicationScene';
    id: string;
    name: string;
    name_t: string;
  }>;
};

export type CompanyApplicationScenesQueryVariables = Exact<{
  companyIdInput: CompanyIdInput;
}>;

export type CompanyApplicationScenesQuery = {
  __typename?: 'Query';
  companyApplicationScenes: Array<{
    __typename?: 'ApplicationScene';
    id: string;
    name: string;
    name_t: string;
  }>;
};

export type AddApplicationSceneMutationVariables = Exact<{
  companySceneInput: CompanySceneInput;
}>;

export type AddApplicationSceneMutation = {
  __typename?: 'Mutation';
  addApplicationScene: boolean;
};

export type AddAllApplicationScenesMutationVariables = Exact<{
  companyIdInput: CompanyIdInput;
}>;

export type AddAllApplicationScenesMutation = {
  __typename?: 'Mutation';
  addAllApplicationScenes: boolean;
};

export type RemoveApplicationSceneMutationVariables = Exact<{
  companySceneInput: CompanySceneInput;
}>;

export type RemoveApplicationSceneMutation = {
  __typename?: 'Mutation';
  removeApplicationScene: boolean;
};

export type UserGroupFragmentFragment = {
  __typename?: 'UserGroup';
  id: string;
  name: string;
};

export type GetUserGroupsQueryVariables = Exact<{
  input: CompanyIdOptionalInput;
}>;

export type GetUserGroupsQuery = {
  __typename?: 'Query';
  userGroups: Array<{ __typename?: 'UserGroup' } & UserGroupFragmentFragment>;
};

export type EditCompanyFeaturesMutationVariables = Exact<{
  input: EditCompanyFeaturesInput;
}>;

export type EditCompanyFeaturesMutation = {
  __typename?: 'Mutation';
  editCompanyFeatures: {
    __typename?: 'Company';
    id: string;
    features: Array<CompanyFeature>;
  };
};

export type CreateUserGroupMutationVariables = Exact<{
  input: CreateUserGroupInput;
}>;

export type CreateUserGroupMutation = {
  __typename?: 'Mutation';
  createUserGroup: { __typename?: 'UserGroup' } & UserGroupFragmentFragment;
};

export type EditUserGroupMutationVariables = Exact<{
  input: EditUserGroupInput;
}>;

export type EditUserGroupMutation = {
  __typename?: 'Mutation';
  editUserGroup: { __typename?: 'UserGroup' } & UserGroupFragmentFragment;
};

export type RemoveUserGroupMutationVariables = Exact<{
  input: RemoveUserGroupInput;
}>;

export type RemoveUserGroupMutation = {
  __typename?: 'Mutation';
  removeUserGroup: boolean;
};

export type HelpFaqsQueryVariables = Exact<{ [key: string]: never }>;

export type HelpFaqsQuery = {
  __typename?: 'Query';
  helpFaqs: Array<{ __typename?: 'HelpFaq'; id: string; name_t: string }>;
};

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;

export type CreateApiKeyMutation = {
  __typename?: 'Mutation';
  createApiKey: {
    __typename?: 'ApiKey';
    id: string;
    name?: Maybe<string>;
    status: UserStatus;
    accessToken?: Maybe<string>;
  };
};

export type EditApiKeyMutationVariables = Exact<{
  input: EditApiKeyInput;
}>;

export type EditApiKeyMutation = {
  __typename?: 'Mutation';
  editApiKey: {
    __typename?: 'ApiKey';
    id: string;
    name?: Maybe<string>;
    status: UserStatus;
  };
};

export type RegenerateApiKeyMutationVariables = Exact<{
  input: RegenerateApiKeyInput;
}>;

export type RegenerateApiKeyMutation = {
  __typename?: 'Mutation';
  regenerateApiKey: { __typename?: 'ApiKey'; accessToken?: Maybe<string> };
};

export type GetApiKeysQueryVariables = Exact<{
  input: GetApiKeysInput;
}>;

export type GetApiKeysQuery = {
  __typename?: 'Query';
  apiKeys: Array<{
    __typename?: 'ApiKey';
    id: string;
    name?: Maybe<string>;
    status: UserStatus;
  }>;
};

export type GetRolePermissionsQueryVariables = Exact<{
  roles: Array<UserRole> | UserRole;
}>;

export type GetRolePermissionsQuery = {
  __typename?: 'Query';
  getRolePermissions: Array<{
    __typename?: 'RolePermissions';
    role: UserRole;
    permissions: Array<UserPermission>;
  }>;
};

export type GetDebugReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDebugReportsQuery = {
  __typename?: 'Query';
  debugReports: Array<{
    __typename?: 'DebugReport';
    id: string;
    ordinal: number;
    version: string;
    reportData: string;
    updatedAt: string;
    scene: { __typename?: 'ApplicationScene'; id: string; name_t: string };
  }>;
};

export type CreateTrainingAssigmentForUserMutationVariables = Exact<{
  input: TrainingAssignmentUsersInput;
}>;

export type CreateTrainingAssigmentForUserMutation = {
  __typename?: 'Mutation';
  addTrainingAssigmentForUsers: Array<{
    __typename?: 'TrainingAssignment';
    playlistTemplateId: string;
    validForDays: number;
    gracePeriodDays: number;
    userId?: Maybe<string>;
    userGroupId?: Maybe<string>;
  }>;
};

export type ManualFragmentFragment = {
  __typename?: 'Manual';
  id: string;
  updatedAt: string;
  name: string;
  description: string;
  enabled: boolean;
  url: string;
};

export type ManualsQueryVariables = Exact<{ [key: string]: never }>;

export type ManualsQuery = {
  __typename?: 'Query';
  manuals: Array<{ __typename?: 'Manual' } & ManualFragmentFragment>;
};

export type CreateManualMutationVariables = Exact<{
  file: Scalars['Upload'];
  manualInput: CreateManualInput;
}>;

export type CreateManualMutation = {
  __typename?: 'Mutation';
  createManual: { __typename?: 'Manual'; id: string };
};

export type SetManualEnabledMutationVariables = Exact<{
  editManualInput: EditManualInput;
}>;

export type SetManualEnabledMutation = {
  __typename?: 'Mutation';
  editManual: { __typename?: 'Manual'; id: string; enabled: boolean };
};

export type EditManualMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
  editManualInput: EditManualInput;
}>;

export type EditManualMutation = {
  __typename?: 'Mutation';
  editManual: { __typename?: 'Manual' } & ManualFragmentFragment;
};

export type DeleteManualMutationVariables = Exact<{
  deleteManualInput: DeleteManualInput;
}>;

export type DeleteManualMutation = {
  __typename?: 'Mutation';
  deleteManual: boolean;
};

export type PatientAdminFragmentFragment = {
  __typename?: 'User';
  id: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  status: UserStatus;
  sessionCumulativeStatistic?: Maybe<{
    __typename?: 'UserSessionCumulativeStatistic';
    id: string;
    numberOfSessions: number;
    allTimeSpent: number;
    lastSession: string;
    mostUsedScene?: Maybe<{
      __typename?: 'ApplicationScene';
      id: string;
      name_t: string;
    }>;
  }>;
  activeSession?: Maybe<{ __typename?: 'Session'; id: string }>;
};

export type UserSessionFragmentFragment = {
  __typename?: 'Session';
  id: string;
  startedAt: string;
  endedAt?: Maybe<string>;
  duration?: Maybe<number>;
  playlist: Array<{
    __typename?: 'SessionPlaylistItem';
    id: string;
    scene: { __typename?: 'ApplicationScene'; id: string; name_t: string };
  }>;
};

export type CreatePatientAdminMutationVariables = Exact<{
  createUserInput: CreateUserInput;
}>;

export type CreatePatientAdminMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'User' } & PatientAdminFragmentFragment;
};

export type EditPatientAdminMutationVariables = Exact<{
  editUserInput: EditUserInput;
}>;

export type EditPatientAdminMutation = {
  __typename?: 'Mutation';
  editUser: { __typename?: 'User'; id: string; lastName?: Maybe<string> };
};

export type GetUsersAdminQueryVariables = Exact<{
  filter?: Maybe<UsersFilterInput>;
  filterBy?: Maybe<UserFilterByInput>;
  orderBy?: Maybe<UserOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetUsersAdminQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<{ __typename?: 'User' } & PatientAdminFragmentFragment>
    >;
  };
};

export type GetUserDetailAdminQueryVariables = Exact<{
  userInput?: Maybe<UserInput>;
}>;

export type GetUserDetailAdminQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User' } & PatientAdminFragmentFragment;
};

export type GetUserSessionsQueryVariables = Exact<{
  filter: SessionFilterInput;
  filterBy?: Maybe<SessionFilterByInput>;
  orderBy?: Maybe<SessionOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetUserSessionsQuery = {
  __typename?: 'Query';
  sessions: {
    __typename?: 'SessionConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<{ __typename?: 'Session' } & UserSessionFragmentFragment>
    >;
  };
};

export type SuspendUsersMutationVariables = Exact<{
  suspendUsersInput: SuspendUsersInput;
}>;

export type SuspendUsersMutation = {
  __typename?: 'Mutation';
  suspendUsers: Array<{ __typename?: 'User'; id: string; status: UserStatus }>;
};

export type SetVrDeviceOsUpdatesEnabledMutationVariables = Exact<{
  input: VrDeviceSysUpdateEnabledInput;
}>;

export type SetVrDeviceOsUpdatesEnabledMutation = {
  __typename?: 'Mutation';
  setVrDeviceOsUpdatesEnabled?: Maybe<{
    __typename?: 'VrDevice';
    id: string;
    osUpdatesEnabled: boolean;
  }>;
};

export type GetVrDevicesForSysUpdateQueryVariables = Exact<{
  filter?: Maybe<VrDeviceFilterInput>;
  filterBy?: Maybe<VrDeviceFilterByInput>;
  orderBy?: Maybe<VrDeviceOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetVrDevicesForSysUpdateQuery = {
  __typename?: 'Query';
  vrDevicesPaginated: {
    __typename?: 'VrDeviceConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<{
        __typename?: 'VrDevice';
        id: string;
        name: string;
        companyName?: Maybe<string>;
        osVersion?: Maybe<string>;
        osUpdatesEnabled: boolean;
        deviceOwner?: Maybe<boolean>;
      }>
    >;
  };
};

export type GetVrDeviceDetailQueryVariables = Exact<{
  vrDeviceInput: VrDeviceInput;
}>;

export type GetVrDeviceDetailQuery = {
  __typename?: 'Query';
  vrDevice: {
    __typename?: 'VrDevice';
    id: string;
    name: string;
    color: string;
    vrBoxId?: Maybe<string>;
    companyId: number;
    status: {
      __typename?: 'VrDeviceStatus';
      battery?: Maybe<number>;
      state: DeviceState;
      updateInProgress: boolean;
    };
  };
};

export type GetVrDeviceStatusQueryVariables = Exact<{
  vrDeviceInput: VrDeviceInput;
}>;

export type GetVrDeviceStatusQuery = {
  __typename?: 'Query';
  vrDevice: {
    __typename?: 'VrDevice';
    id: string;
    status: {
      __typename?: 'VrDeviceStatus';
      battery?: Maybe<number>;
      state: DeviceState;
      updateInProgress: boolean;
    };
  };
};

export type GetVrDeviceSessionsQueryVariables = Exact<{
  filter: SessionFilterInput;
  filterBy?: Maybe<SessionFilterByInput>;
  orderBy?: Maybe<SessionOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetVrDeviceSessionsQuery = {
  __typename?: 'Query';
  sessions: {
    __typename?: 'SessionConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<{
        __typename?: 'Session';
        id: string;
        startedAt: string;
        user?: Maybe<{
          __typename?: 'User';
          id: string;
          firstName?: Maybe<string>;
          lastName?: Maybe<string>;
        }>;
        playlist: Array<{
          __typename?: 'SessionPlaylistItem';
          id: string;
          scene: {
            __typename?: 'ApplicationScene';
            id: string;
            name_t: string;
          };
        }>;
      }>
    >;
  };
};

export type RegisterVrDeviceMutationVariables = Exact<{
  deviceRegistration: RegisterDeviceInput;
}>;

export type RegisterVrDeviceMutation = {
  __typename?: 'Mutation';
  registerVrDevice: { __typename?: 'VrDevice'; id: string; name: string };
};

export type EditVrDeviceMutationVariables = Exact<{
  editVrDeviceInput: EditVrDeviceInput;
}>;

export type EditVrDeviceMutation = {
  __typename?: 'Mutation';
  editVrDevice: {
    __typename?: 'VrDevice';
    id: string;
    name: string;
    color: string;
    vrBoxId?: Maybe<string>;
    companyId: number;
  };
};

export type LaunchUrlMutationVariables = Exact<{
  input: LaunchUrlInput;
}>;

export type LaunchUrlMutation = { __typename?: 'Mutation'; launchUrl: boolean };

export type DeleteVrDeviceMutationVariables = Exact<{
  deleteVrDeviceInput: VrDeviceIdInput;
}>;

export type DeleteVrDeviceMutation = {
  __typename?: 'Mutation';
  deleteVrDevice: boolean;
};

export type GetVrSessionQueryVariables = Exact<{
  sessionInput: SessionInput;
}>;

export type GetVrSessionQuery = {
  __typename?: 'Query';
  session: {
    __typename?: 'Session';
    id: string;
    startedAt: string;
    endedAt?: Maybe<string>;
    vrDevice: {
      __typename?: 'VrDevice';
      id: string;
      name: string;
      color: string;
      status: {
        __typename?: 'VrDeviceStatus';
        battery?: Maybe<number>;
        state: DeviceState;
        updateInProgress: boolean;
      };
    };
    user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>;
    playlist: Array<{
      __typename?: 'SessionPlaylistItem';
      id: string;
      scene: { __typename?: 'ApplicationScene'; id: string; name_t: string };
    }>;
  };
};

export type AppointUserToSessionMutationVariables = Exact<{
  addUserToSessionInput: AddUserToSessionInput;
}>;

export type AppointUserToSessionMutation = {
  __typename?: 'Mutation';
  addUserToSession: {
    __typename?: 'Session';
    id: string;
    user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>;
  };
};

export type DisappointUserToSessionMutationVariables = Exact<{
  removeUserFromSessionInput: RemoveUserFromSessionInput;
}>;

export type DisappointUserToSessionMutation = {
  __typename?: 'Mutation';
  removeUserFromSession: {
    __typename?: 'Session';
    id: string;
    user?: Maybe<{ __typename?: 'User' } & UserFragmentFragment>;
  };
};

export type DeleteVrSessionMutationVariables = Exact<{
  deleteSessionInput: DeleteSessionInput;
}>;

export type DeleteVrSessionMutation = {
  __typename?: 'Mutation';
  deleteSession: boolean;
};

export type GetIceServerConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetIceServerConfigQuery = {
  __typename?: 'Query';
  iceServerConfig: {
    __typename?: 'IceServerConfig';
    iceServers: Array<{
      __typename?: 'IceServer';
      urls: Array<string>;
      credentialType?: Maybe<string>;
      username?: Maybe<string>;
      credential?: Maybe<string>;
    }>;
  };
};

export type WebRtcCandidateMutationVariables = Exact<{
  candidate: WebRtcCandidateMessageInput;
}>;

export type WebRtcCandidateMutation = {
  __typename?: 'Mutation';
  webRtcCandidate: boolean;
};

export type WebRtcNewOfferMutationVariables = Exact<{
  offerReq: WebRtcNewOfferInput;
}>;

export type WebRtcNewOfferMutation = {
  __typename?: 'Mutation';
  webRtcNewOffer: string;
};

export type WebRtsAnswerSubscriptionVariables = Exact<{
  sessionId: Scalars['String'];
}>;

export type WebRtsAnswerSubscription = {
  __typename?: 'Subscription';
  webRtsAnswer: { __typename?: 'WebRtcSessionDescription'; sdp: string };
};

export type WebRtcDeviceCandidateSubscriptionVariables = Exact<{
  sessionId: Scalars['String'];
}>;

export type WebRtcDeviceCandidateSubscription = {
  __typename?: 'Subscription';
  webRtcDeviceCandidate: {
    __typename?: 'WebRtcCandidate';
    sdpMLineIndex?: Maybe<number>;
    sdpMid?: Maybe<string>;
    candidate: string;
  };
};

export type VrDeviceFragmentForUpdateFragment = {
  __typename?: 'VrDevice';
  id: string;
  status: {
    __typename?: 'VrDeviceStatus';
    battery?: Maybe<number>;
    state: DeviceState;
    updateInProgress: boolean;
  };
};

export type GetVrDevicesQueryVariables = Exact<{
  companyIdOptionalInput: CompanyIdOptionalInput;
}>;

export type GetVrDevicesQuery = {
  __typename?: 'Query';
  vrDevices: Array<{
    __typename?: 'VrDevice';
    id: string;
    name: string;
    color: string;
    upToDate: boolean;
    vrBoxId?: Maybe<string>;
    deviceOwner?: Maybe<boolean>;
    session?: Maybe<{ __typename?: 'Session'; id: string }>;
    status: {
      __typename?: 'VrDeviceStatus';
      battery?: Maybe<number>;
      state: DeviceState;
      updateInProgress: boolean;
    };
  }>;
};

export type GetDevicesChangeSubscriptionSubscriptionVariables = Exact<{
  companyIdOptionalInput: CompanyIdOptionalInput;
}>;

export type GetDevicesChangeSubscriptionSubscription = {
  __typename?: 'Subscription';
  vrDevicesChanges:
    | {
        __typename?: 'VrDeviceStatusUpdateEvent';
        deviceId: string;
        battery?: Maybe<number>;
        state: DeviceState;
        updateInProgress: boolean;
      }
    | {
        __typename?: 'VrDeviceRegistered';
        device: {
          __typename?: 'VrDevice';
          id: string;
          name: string;
          color: string;
          upToDate: boolean;
          deviceOwner?: Maybe<boolean>;
          session?: Maybe<{ __typename?: 'Session'; id: string }>;
          status: {
            __typename?: 'VrDeviceStatus';
            battery?: Maybe<number>;
            state: DeviceState;
            updateInProgress: boolean;
          };
        };
      }
    | {
        __typename?: 'VrDeviceUnRegistered';
        device: { __typename?: 'VrDevice'; id: string };
      }
    | {
        __typename?: 'DeviceSessionStarted';
        deviceId: string;
        sessionId: string;
      }
    | { __typename?: 'DeviceSessionEnded'; deviceId: string }
    | {
        __typename?: 'VrDeviceAppsUpdated';
        device: { __typename?: 'VrDevice'; id: string; upToDate: boolean };
      };
};

export type GetApplicationScenesQueryVariables = Exact<{
  filter: ApplicationSceneFilterInput;
}>;

export type GetApplicationScenesQuery = {
  __typename?: 'Query';
  applicationScenes: Array<{
    __typename?: 'ApplicationScene';
    id: string;
    name_t: string;
    enabled: boolean;
    imageUrl?: Maybe<string>;
    manualUrl?: Maybe<string>;
    parameters: Array<{
      __typename?: 'SceneParameter';
      id: string;
      name: string;
      name_t: string;
      required: boolean;
      filtered: boolean;
      ordinal?: Maybe<number>;
      values: Array<{
        __typename?: 'SceneParameterValue';
        id: string;
        name: string;
        name_t: string;
        enabled: boolean;
        ordinal?: Maybe<number>;
      }>;
    }>;
  }>;
};

export type GetHistoricPlaylistQueryVariables = Exact<{
  sessionId: Scalars['ID'];
}>;

export type GetHistoricPlaylistQuery = {
  __typename?: 'Query';
  session: {
    __typename?: 'Session';
    id: string;
    playlist: Array<{
      __typename?: 'SessionPlaylistItem';
      ordinal: number;
      scene: { __typename?: 'ApplicationScene'; id: string };
      params: Array<{
        __typename?: 'SessionPlaylistParamModel';
        paramId: string;
        valueId?: Maybe<string>;
      }>;
    }>;
  };
};

export type UpdateAppsOnVrDeviceMutationVariables = Exact<{
  vrDeviceId: Scalars['ID'];
}>;

export type UpdateAppsOnVrDeviceMutation = {
  __typename?: 'Mutation';
  updateAppsOnVrDevice: boolean;
};

export type GetDeviceByIdQueryVariables = Exact<{
  vrDeviceId: Scalars['ID'];
}>;

export type GetDeviceByIdQuery = {
  __typename?: 'Query';
  vrDevice: {
    __typename?: 'VrDevice';
    id: string;
    name: string;
    companyId: number;
    vrBoxId?: Maybe<string>;
  };
};

export type GetAvailableHelpFaqQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableHelpFaqQuery = {
  __typename?: 'Query';
  availableHelpFaq: {
    __typename?: 'HelpFaq';
    id: string;
    name_t: string;
    content_t?: Maybe<string>;
  };
};

export type SendHelpFaqResultMutationVariables = Exact<{
  sendHelpFaqResult: SendHelpFaqResultInput;
}>;

export type SendHelpFaqResultMutation = {
  __typename?: 'Mutation';
  sendHelpFaqResult: boolean;
};

export type PlaylistTemplateListFragmentFragment = {
  __typename?: 'PlaylistTemplate';
  id: string;
  name: string;
  owner: {
    __typename?: 'User';
    id: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
  };
  items: Array<{
    __typename?: 'PlaylistTemplateItem';
    id: string;
    ordinal: number;
    scene: {
      __typename?: 'ApplicationScene';
      id: string;
      name_t: string;
      imageUrl?: Maybe<string>;
    };
  }>;
};

export type GetPlaylistTemplatesQueryVariables = Exact<{
  filter?: Maybe<PlaylistTemplateFilterInput>;
  filterBy?: Maybe<PlaylistTemplateFilterByInput>;
  orderBy?: Maybe<PlaylistTemplateOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetPlaylistTemplatesQuery = {
  __typename?: 'Query';
  playlistTemplates: {
    __typename?: 'PlaylistTemplateConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<
        {
          __typename?: 'PlaylistTemplate';
        } & PlaylistTemplateListFragmentFragment
      >
    >;
  };
};

export type PlaylistTemplateFragmentFragment = {
  __typename?: 'PlaylistTemplate';
  id: string;
  name: string;
  enforceOrder: boolean;
  owner: {
    __typename?: 'User';
    id: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
  };
  items: Array<{
    __typename?: 'PlaylistTemplateItem';
    id: string;
    ordinal: number;
    scene: {
      __typename?: 'ApplicationScene';
      id: string;
      name_t: string;
      imageUrl?: Maybe<string>;
    };
    params: Array<{
      __typename?: 'PlaylistTemplateItemParam';
      paramId: string;
      valueId?: Maybe<string>;
      name_t: string;
      value_t: string;
      name: string;
      value: string;
    }>;
  }>;
  certificateTemplate?: Maybe<
    { __typename?: 'CertificateTemplate' } & CertificateTemplateFragmentFragment
  >;
};

export type GetPlaylistTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPlaylistTemplateQuery = {
  __typename?: 'Query';
  playlistTemplate: {
    __typename?: 'PlaylistTemplate';
  } & PlaylistTemplateFragmentFragment;
};

export type GetPlaylistTemplateCompaniesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPlaylistTemplateCompaniesQuery = {
  __typename?: 'Query';
  playlistTemplate: {
    __typename?: 'PlaylistTemplate';
    id: string;
    assignedToCompanies: Array<{ __typename?: 'Company'; id: string }>;
  };
};

export type GetUnassignedPlaylistTemplatesQueryVariables = Exact<{
  input: GetUnassignedPlaylistTemplatesInput;
}>;

export type GetUnassignedPlaylistTemplatesQuery = {
  __typename?: 'Query';
  unassignedPlaylistTemplates: Array<{
    __typename?: 'PlaylistTemplate';
    id: string;
    name: string;
  }>;
};

export type GetFoldersQueryVariables = Exact<{
  input: GetCompanyFoldersInput;
}>;

export type GetFoldersQuery = {
  __typename?: 'Query';
  folders: Array<{
    __typename?: 'Folder';
    id: string;
    name: string;
    playlistTemplates: Array<{ __typename?: 'PlaylistTemplate'; id: string }>;
  }>;
};

export type GetPlaylistTemplateFoldersQueryVariables = Exact<{
  input: GetPlaylistTemplateFoldersInput;
}>;

export type GetPlaylistTemplateFoldersQuery = {
  __typename?: 'Query';
  playlistTemplateFolders: Array<{
    __typename?: 'Folder';
    id: string;
    name: string;
  }>;
};

export type DeletePlaylistTemplateFolderMutationVariables = Exact<{
  input: DeletePlaylistTemplateFolderInput;
}>;

export type DeletePlaylistTemplateFolderMutation = {
  __typename?: 'Mutation';
  deletePlaylistTemplateFolder: boolean;
};

export type FolderFragment = {
  __typename?: 'Folder';
  id: string;
  name: string;
  playlistTemplatesCount: number;
};

export type GetPlaylistTemplatesFoldersQueryVariables = Exact<{
  filter?: Maybe<PlaylistTemplatesFoldersFilterInput>;
  filterBy?: Maybe<PlaylistTemplatesFoldersFilterByInput>;
  orderBy?: Maybe<PlaylistTemplatesFoldersOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetPlaylistTemplatesFoldersQuery = {
  __typename?: 'Query';
  playlistTemplatesFolders: {
    __typename?: 'PlaylistTemplateFolderConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<Array<{ __typename?: 'Folder' } & FolderFragment>>;
  };
};

export type GetPlaylistTemplateFolderCompaniesQueryVariables = Exact<{
  input: GetFolderInput;
}>;

export type GetPlaylistTemplateFolderCompaniesQuery = {
  __typename?: 'Query';
  folder: {
    __typename?: 'Folder';
    assignedCompanies: Array<{
      __typename?: 'Company';
      id: string;
      name: string;
    }>;
  } & FolderFragment;
};

export type GetPlaylistTemplateFolderQueryVariables = Exact<{
  input: GetFolderInput;
}>;

export type GetPlaylistTemplateFolderQuery = {
  __typename?: 'Query';
  folder: {
    __typename?: 'Folder';
    playlistTemplates: Array<
      { __typename?: 'PlaylistTemplate' } & PlaylistTemplateListFragmentFragment
    >;
  } & FolderFragment;
};

export type CreatePlaylistTemplateFolderMutationVariables = Exact<{
  input: CreatePlaylistTemplateFolderInput;
}>;

export type CreatePlaylistTemplateFolderMutation = {
  __typename?: 'Mutation';
  createPlaylistTemplateFolder: { __typename?: 'Folder' } & FolderFragment;
};

export type EditPlaylistTemplateFolderMutationVariables = Exact<{
  input: EditPlaylistTemplateFolderInput;
}>;

export type EditPlaylistTemplateFolderMutation = {
  __typename?: 'Mutation';
  editPlaylistTemplateFolder: { __typename?: 'Folder' } & FolderFragment;
};

export type ChangePlaylistTemplateCompanyAssignmentMutationVariables = Exact<{
  input: PlaylistTemplateAssignmentInput;
}>;

export type ChangePlaylistTemplateCompanyAssignmentMutation = {
  __typename?: 'Mutation';
  changePlaylistTemplateCompanyAssignment: {
    __typename?: 'PlaylistTemplate';
    id: string;
    assignedToCompanies: Array<{ __typename?: 'Company'; id: string }>;
  };
};

export type ChangePlaylistTemplateFolderCompanyAssignmentMutationVariables =
  Exact<{
    input: PlaylistTemplateFoldersAssignmentInput;
  }>;

export type ChangePlaylistTemplateFolderCompanyAssignmentMutation = {
  __typename?: 'Mutation';
  changePlaylistTemplateFoldersCompanyAssignment: {
    __typename?: 'PlaylistTemplate';
    id: string;
    assignedToCompanies: Array<{ __typename?: 'Company'; id: string }>;
  };
};

export type CreatePlaylistTemplateMutationVariables = Exact<{
  template: CreatePlaylistTemplateInput;
}>;

export type CreatePlaylistTemplateMutation = {
  __typename?: 'Mutation';
  createPlaylistTemplate: {
    __typename?: 'PlaylistTemplate';
  } & PlaylistTemplateFragmentFragment;
};

export type EditPlaylistTemplateMutationVariables = Exact<{
  template: EditPlaylistTemplateInput;
}>;

export type EditPlaylistTemplateMutation = {
  __typename?: 'Mutation';
  editPlaylistTemplate: {
    __typename?: 'PlaylistTemplate';
  } & PlaylistTemplateFragmentFragment;
};

export type DeletePlaylistTemplateMutationVariables = Exact<{
  input: DeletePlaylistTemplateInput;
}>;

export type DeletePlaylistTemplateMutation = {
  __typename?: 'Mutation';
  deletePlaylistTemplate: boolean;
};

export type AddTrainingAssigmentForUsersMutationVariables = Exact<{
  input: TrainingAssignmentUsersInput;
}>;

export type AddTrainingAssigmentForUsersMutation = {
  __typename?: 'Mutation';
  addTrainingAssigmentForUsers: Array<{
    __typename?: 'TrainingAssignment';
    id: string;
  }>;
};

export type AddTrainingAssigmentForUserGroupsMutationVariables = Exact<{
  input: TrainingAssignmentUserGroupsInput;
}>;

export type AddTrainingAssigmentForUserGroupsMutation = {
  __typename?: 'Mutation';
  addTrainingAssigmentForUserGroups: Array<{
    __typename?: 'TrainingAssignment';
    id: string;
  }>;
};

export type GetTrainingAssignmentsQueryVariables = Exact<{
  input: TrainingAssignmentFilterInput;
}>;

export type GetTrainingAssignmentsQuery = {
  __typename?: 'Query';
  trainingAssignments: Array<{
    __typename?: 'TrainingAssignment';
    id: string;
    userGroupId?: Maybe<string>;
    userId?: Maybe<string>;
  }>;
};

export type GetTrainingAssignmentForTemplateQueryVariables = Exact<{
  companyId: Scalars['ID'];
  playlistTemplateIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetTrainingAssignmentForTemplateQuery = {
  __typename?: 'Query';
  trainingAssignments: Array<{
    __typename?: 'TrainingAssignment';
    id: string;
    userGroup?: Maybe<{ __typename?: 'UserGroup' } & UserGroupFragmentFragment>;
    user?: Maybe<{ __typename?: 'User' } & UserListFragmentFragment>;
  }>;
};

export type CertificateTemplateFragmentFragment = {
  __typename?: 'CertificateTemplate';
  id: string;
  name: string;
  url: string;
  sendCcTo?: Maybe<string>;
  sendToTrainees: boolean;
};

export type CreateCertificateTemplateMutationVariables = Exact<{
  file: Scalars['Upload'];
  input: CreateCertificateTemplateInput;
}>;

export type CreateCertificateTemplateMutation = {
  __typename?: 'Mutation';
  createCertificateTemplate: {
    __typename?: 'CertificateTemplate';
  } & CertificateTemplateFragmentFragment;
};

export type EditCertificateTemplateMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
  input: EditCertificateTemplateInput;
}>;

export type EditCertificateTemplateMutation = {
  __typename?: 'Mutation';
  editCertificateTemplate: {
    __typename?: 'CertificateTemplate';
  } & CertificateTemplateFragmentFragment;
};

export type DeleteCertificateTemplateMutationVariables = Exact<{
  input: DeleteCertificateTemplateInput;
}>;

export type DeleteCertificateTemplateMutation = {
  __typename?: 'Mutation';
  deleteCertificateTemplate: boolean;
};

export type SessionPlaylistItemReportFragmentFragment = {
  __typename?: 'SessionPlaylistItemReport';
  id: string;
  startedAt?: Maybe<string>;
  endedAt?: Maybe<string>;
  durationMs: number;
  completed: boolean;
  countTowardsCompanies: Array<string>;
  vrDevice: { __typename?: 'VrDevice'; id: string; name: string };
  company: {
    __typename?: 'Company';
    id: string;
    name: string;
    parentCompanyId?: Maybe<string>;
  };
  launchedByUser?: Maybe<{
    __typename?: 'User';
    id: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    externalId?: Maybe<string>;
  }>;
  scene: { __typename?: 'ApplicationScene'; id: string; name_t: string };
};

export type GetSessionPlaylistItemReportsMutationMutationVariables = Exact<{
  filter: SessionPlaylistItemReportFilter;
}>;

export type GetSessionPlaylistItemReportsMutationMutation = {
  __typename?: 'Mutation';
  sessionPlaylistItemReportsMutation: Array<
    {
      __typename?: 'SessionPlaylistItemReport';
    } & SessionPlaylistItemReportFragmentFragment
  >;
};

export type PlaylistItemFragmentFragment = {
  __typename?: 'SessionPlaylistItem';
  id: string;
  ordinal: number;
  startedAt?: Maybe<string>;
  endedAt?: Maybe<string>;
  scene: {
    __typename?: 'ApplicationScene';
    id: string;
    name_t: string;
    imageUrl?: Maybe<string>;
  };
  params: Array<{
    __typename?: 'SessionPlaylistParamModel';
    paramId: string;
    name: string;
    name_t: string;
    value_t: string;
  }>;
};

export type VrSessionFragmentFragment = {
  __typename?: 'Session';
  id: string;
  startedAt: string;
  endedAt?: Maybe<string>;
  playlist: Array<
    { __typename?: 'SessionPlaylistItem' } & PlaylistItemFragmentFragment
  >;
  user?: Maybe<{ __typename?: 'User'; id: string; lastName?: Maybe<string> }>;
  vrDevice: { __typename?: 'VrDevice'; id: string; name: string };
};

export type GetVrSessionDetailQueryVariables = Exact<{
  vrSessionId: Scalars['ID'];
}>;

export type GetVrSessionDetailQuery = {
  __typename?: 'Query';
  session: { __typename?: 'Session' } & VrSessionFragmentFragment;
};

export type ObserveVrSessionChangesSubscriptionVariables = Exact<{
  vrSessionId: Scalars['ID'];
}>;

export type ObserveVrSessionChangesSubscription = {
  __typename?: 'Subscription';
  sessionChange: { __typename?: 'Session' } & VrSessionFragmentFragment;
};

export type EndVrSessionMutationVariables = Exact<{
  endSessionInput: EndSessionInput;
}>;

export type EndVrSessionMutation = {
  __typename?: 'Mutation';
  endSession: { __typename?: 'Session' } & VrSessionFragmentFragment;
};

export type LaunchNextInVrSessionMutationVariables = Exact<{
  nextInput: LaunchNextInput;
}>;

export type LaunchNextInVrSessionMutation = {
  __typename?: 'Mutation';
  launchNextInVrSession: { __typename?: 'Session' } & VrSessionFragmentFragment;
};

export type StopSessionOnDevicesMutationVariables = Exact<{
  deviceIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type StopSessionOnDevicesMutation = {
  __typename?: 'Mutation';
  stopSessionOnDevices: boolean;
};

export type IncreaseDeviceVolumeMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;

export type IncreaseDeviceVolumeMutation = {
  __typename?: 'Mutation';
  increaseDeviceVolume: boolean;
};

export type DecreaseDeviceVolumeMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;

export type DecreaseDeviceVolumeMutation = {
  __typename?: 'Mutation';
  decreaseDeviceVolume: boolean;
};

export type RestartDeviceMutationVariables = Exact<{
  deviceId: Scalars['ID'];
}>;

export type RestartDeviceMutation = {
  __typename?: 'Mutation';
  restartDevice: boolean;
};

export type UserTrainingReportsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserTrainingReportsQuery = {
  __typename?: 'Query';
  userTrainingReports: {
    __typename?: 'UserTrainingReport';
    id: string;
    trainingReports: Array<{
      __typename?: 'TrainingReportRun';
      reportId: string;
      sceneId: string;
      date: string;
      sceneName: string;
    }>;
  };
};

export type UserTrainingReportDetailQueryVariables = Exact<{
  userId: Scalars['ID'];
  reportId: Scalars['ID'];
}>;

export type UserTrainingReportDetailQuery = {
  __typename?: 'Query';
  userTrainingReports: {
    __typename?: 'UserTrainingReport';
    id: string;
    trainingDetail?: Maybe<{
      __typename?: 'UserTrainingDetailReport';
      id: string;
      result?: Maybe<string>;
      sceneId: string;
      sceneName: string;
      sequences: Array<{
        __typename?: 'UserTrainingSequenceResult';
        name?: Maybe<string>;
        onTime?: Maybe<string>;
        correctCount?: Maybe<number>;
        totalCount?: Maybe<number>;
        mistakes: Array<string>;
      }>;
    }>;
  };
};

export type UserTrainingFragmentFragment = {
  __typename?: 'UserTrainingAssignment';
  id: string;
  validForDays: number;
  gracePeriodDays: number;
  trainingAssignmentId: string;
  userGroup?: Maybe<{ __typename?: 'UserGroup'; id: string }>;
  playlistTemplate: {
    __typename?: 'PlaylistTemplate';
    id: string;
    name: string;
    enforceOrder: boolean;
    certificateTemplate?: Maybe<{
      __typename?: 'CertificateTemplate';
      id: string;
    }>;
    items: Array<{
      __typename?: 'PlaylistTemplateItem';
      id: string;
      scene: {
        __typename?: 'ApplicationScene';
        id: string;
        name_t: string;
        imageUrl?: Maybe<string>;
      };
      params: Array<{
        __typename?: 'PlaylistTemplateItemParam';
        paramId: string;
        valueId?: Maybe<string>;
        name: string;
        value: string;
        value_t: string;
      }>;
    }>;
  };
  completion: {
    __typename?: 'UserTrainingCompletionStatus';
    id: string;
    itemsTotal: number;
    itemsCompleted: number;
    dueDate: string;
    completedItems: Array<{
      __typename?: 'UserTrainingcompletionItemStatus';
      id: string;
      sceneId: string;
      completionDate: string;
    }>;
  };
  certificate?: Maybe<
    {
      __typename?: 'UserTrainingCertificate';
    } & UserTrainingCertificateFragmentFragment
  >;
};

export type UserTrainingCertificateFragmentFragment = {
  __typename?: 'UserTrainingCertificate';
  id: string;
  trainingAssignmentId: number;
  url: string;
};

export type GetUserTrainingAssignmentsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetUserTrainingAssignmentsQuery = {
  __typename?: 'Query';
  userTrainingAssignments: Array<
    { __typename?: 'UserTrainingAssignment' } & UserTrainingFragmentFragment
  >;
};

export type GetUserTrainingAssignmentQueryVariables = Exact<{
  userTrainingId: Scalars['ID'];
}>;

export type GetUserTrainingAssignmentQuery = {
  __typename?: 'Query';
  userTrainingAssignment: {
    __typename?: 'UserTrainingAssignment';
    user: { __typename?: 'User'; id: string };
  } & UserTrainingFragmentFragment;
};

export type GenerateUserTrainingCertificateMutationVariables = Exact<{
  userTrainingId: Scalars['ID'];
}>;

export type GenerateUserTrainingCertificateMutation = {
  __typename?: 'Mutation';
  generateUserTrainingCertificate: {
    __typename?: 'UserTrainingAssignment';
    id: string;
    certificate?: Maybe<
      {
        __typename?: 'UserTrainingCertificate';
      } & UserTrainingCertificateFragmentFragment
    >;
  };
};

export type GetUserRuntimeReportQueryVariables = Exact<{
  userId: Scalars['ID'];
  toDate?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
}>;

export type GetUserRuntimeReportQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    sessionCount: number;
    gameTimeSeconds: number;
    runTimeSeconds: number;
    gameTimeLastSession?: Maybe<{
      __typename?: 'UserReportProgress';
      docCount: number;
      key: string;
      gameTimeSeconds: number;
      runTimeSeconds: number;
    }>;
  };
};

export type GetUserRuntimeReportSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUserRuntimeReportSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    gameTimeProgressSeries: Array<{
      __typename?: 'UserReportProgress';
      docCount: number;
      key: string;
      gameTimeSeconds: number;
      runTimeSeconds: number;
    }>;
  };
};

export type GetUserShoulderRangeReportQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
}>;

export type GetUserShoulderRangeReportQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    shouldersRangeChange?: Maybe<{
      __typename?: 'UserReportRangeChange';
      leftPercent: number;
      leftChange: number;
      rightPercent: number;
      rightChange: number;
    }>;
  };
};

export type GetUserShoulderRangeReportSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUserShoulderRangeReportSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    shouldersRangeSeries: Array<{
      __typename?: 'UserReportShouldersRange';
      key: string;
      docCount: number;
      maxShoulderLeft: number;
      maxShoulderRight: number;
    }>;
  };
};

export type GetUserArmsAbductionRangeReportQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
}>;

export type GetUserArmsAbductionRangeReportQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    armsAbductionChange?: Maybe<{
      __typename?: 'UserReportRangeChange';
      leftPercent: number;
      leftChange: number;
      rightPercent: number;
      rightChange: number;
    }>;
  };
};

export type GetUserArmsAbductionRangeReportSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUserArmsAbductionRangeReportSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    armsAbductionSeries: Array<{
      __typename?: 'UserReportArmsAbduction';
      key: string;
      docCount: number;
      maxArmAbductionLeft: number;
      maxArmAbductionRight: number;
    }>;
  };
};

export type GetUserArmsFlexReportQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
}>;

export type GetUserArmsFlexReportQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    armsFlexChange?: Maybe<{
      __typename?: 'UserReportRangeChange';
      leftPercent: number;
      leftChange: number;
      rightPercent: number;
      rightChange: number;
    }>;
  };
};

export type GetUserArmsFlexReportSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUserArmsFlexReportSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    armsFlexSeries: Array<{
      __typename?: 'UserReportArmsFlex';
      key: string;
      docCount: number;
      maxArmFlexLeft: number;
      maxArmFlexRight: number;
    }>;
  };
};

export type GetUserFineMotoricsReportSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUserFineMotoricsReportSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    fineMotoricsSeries: Array<{
      __typename?: 'UserReportFineMotorics';
      key: string;
      docCount: number;
      leftTotal: number;
      leftPosition1: number;
      leftPosition2: number;
      leftPosition3: number;
      leftPosition4: number;
      leftPosition5: number;
      rightTotal: number;
      rightPosition1: number;
      rightPosition2: number;
      rightPosition3: number;
      rightPosition4: number;
      rightPosition5: number;
    }>;
  };
};

export type GetLimbMovementIntervalsQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetLimbMovementIntervalsQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    upperLimbMovementIntervals: Array<string>;
  };
};

export type GetLimbMovementSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
}>;

export type GetLimbMovementSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    upperLimbMovementSeries: Array<{
      __typename?: 'UpperLimbMovement';
      key: string;
      sceneId: string;
      sceneName: string;
      left: Array<{
        __typename?: 'MovementVector';
        x: number;
        y: number;
        z: number;
      }>;
      right: Array<{
        __typename?: 'MovementVector';
        x: number;
        y: number;
        z: number;
      }>;
    }>;
  };
};

export type GetHeadMovementIntervalsQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetHeadMovementIntervalsQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    headMovementIntervals: Array<string>;
  };
};

export type GetHeadMovementSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
}>;

export type GetHeadMovementSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    headMovementSeries: Array<{
      __typename?: 'HeadMovement';
      key: string;
      sceneId: string;
      sceneName: string;
      data: Array<{
        __typename?: 'MovementVector';
        x: number;
        y: number;
        z: number;
        time: number;
      }>;
    }>;
  };
};

export type GetHeadRotationDataQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetHeadRotationDataQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    headRotationRanges: {
      __typename?: 'HeadRotation';
      maxX: number;
      minX: number;
      maxY: number;
      minY: number;
      maxZ: number;
      minZ: number;
    };
    headRotationSeries: Array<{
      __typename?: 'HeadRotationSeries';
      key: string;
      docCount: number;
      maxX: number;
      minX: number;
      maxY: number;
      minY: number;
      maxZ: number;
      minZ: number;
    }>;
  };
};

export type GetUpperLimbActivityReportQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
}>;

export type GetUpperLimbActivityReportQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    upperLimbActivityChange: {
      __typename?: 'UpperLimbActivityChange';
      leftActiveMax: number;
      leftActiveFirst: number;
      leftActiveLast: number;
      leftTotalFirst: number;
      leftTotalLast: number;
      rightActiveMax: number;
      rightActiveFirst: number;
      rightActiveLast: number;
      rightTotalFirst: number;
      rightTotalLast: number;
    };
  };
};

export type GetUpperLimbActivitySeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUpperLimbActivitySeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    upperLimbActivitySeries: Array<{
      __typename?: 'UpperLimbActivitySeries';
      key: string;
      docCount: number;
      leftActive: number;
      leftTotal: number;
      rightActive: number;
      rightTotal: number;
    }>;
  };
};

export type GetUserReactionTimeSeriesQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  seriesInterval?: Maybe<SeriesAggregationInterval>;
}>;

export type GetUserReactionTimeSeriesQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    reactionTimeSeries: Array<{
      __typename?: 'ReactionTimeSeries';
      key: string;
      leftArm: number;
      leftFingers: number;
      rightArm: number;
      rightFingers: number;
    }>;
  };
};

export type GetUserReactionTimeRangeReportQueryVariables = Exact<{
  userId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
}>;

export type GetUserReactionTimeRangeReportQuery = {
  __typename?: 'Query';
  userReports: {
    __typename?: 'UserReport';
    id: string;
    reactionTimeChange?: Maybe<{
      __typename?: 'ReactionTimeChange';
      leftArmFirst: number;
      leftArmLast: number;
      leftFingersFirst: number;
      leftFingersLast: number;
      rightArmFirst: number;
      rightArmLast: number;
      rightFingersFirst: number;
      rightFingersLast: number;
    }>;
  };
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  companyId?: Maybe<number>;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  externalId?: Maybe<string>;
  role: UserRole;
  status: UserStatus;
  phonePrefix?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  loginType?: Maybe<LoginType>;
  email?: Maybe<string>;
  username?: Maybe<string>;
  hasPassword: boolean;
  groups: Array<{ __typename?: 'UserGroup'; id: string; name: string }>;
  diagnosis?: Maybe<{ __typename?: 'UserDiagnosis'; id: string; name: string }>;
};

export type UserDetailFragmentFragment = {
  __typename?: 'User';
  id: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  externalId?: Maybe<string>;
  role: UserRole;
  status: UserStatus;
  phonePrefix?: Maybe<string>;
  phoneNumber?: Maybe<string>;
  groups: Array<{ __typename?: 'UserGroup'; id: string; name: string }>;
  sessionCumulativeStatistic?: Maybe<{
    __typename?: 'UserSessionCumulativeStatistic';
    id: string;
    numberOfSessions: number;
  }>;
};

export type GetUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User' } & UserFragmentFragment;
};

export type GetUserDetailQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;

export type GetUserDetailQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User' } & UserDetailFragmentFragment;
};

export type GetUserForPasswordChangeQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;

export type GetUserForPasswordChangeQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string; hasPassword: boolean };
};

export type CreateUserMutationVariables = Exact<{
  companyId: Scalars['ID'];
  lastName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  diagnosis?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  loginType?: Maybe<LoginType>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'User' } & UserFragmentFragment;
};

export type GetUsersQueryVariables = Exact<{
  filter?: Maybe<UsersFilterInput>;
  filterBy?: Maybe<UserFilterByInput>;
  orderBy?: Maybe<UserOrderByInput>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<Array<{ __typename?: 'User' } & UserListFragmentFragment>>;
  };
};

export type UserListFragmentFragment = {
  __typename?: 'User';
  id: string;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  externalId?: Maybe<string>;
  role: UserRole;
  groups: Array<{ __typename?: 'UserGroup'; id: string; name: string }>;
  trainingCompletion?: Maybe<{
    __typename?: 'UserTrainingCompletionAgregated';
    id: string;
    completed: boolean;
    dueDate: string;
    completedDate?: Maybe<string>;
  }>;
  sessionCumulativeStatistic?: Maybe<{
    __typename?: 'UserSessionCumulativeStatistic';
    id: string;
    lastSession: string;
  }>;
};

export type EditUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  lastName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  role: UserRole;
  diagnosis?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  loginType?: Maybe<LoginType>;
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser: { __typename?: 'User' } & UserFragmentFragment;
};

export type AddTrainingAssigmentsForUserMutationVariables = Exact<{
  input: TrainingAssignmentsUserInput;
}>;

export type AddTrainingAssigmentsForUserMutation = {
  __typename?: 'Mutation';
  addTrainingAssigmentsForUser: Array<{
    __typename?: 'TrainingAssignment';
    id: string;
  }>;
};

export type RemoveTrainingAssignmentsMutationVariables = Exact<{
  input: TrainingAssignmentRemoveInput;
}>;

export type RemoveTrainingAssignmentsMutation = {
  __typename?: 'Mutation';
  removeTrainingAssignments: boolean;
};

export type UserSessionPlaylistFragmentFragment = {
  __typename?: 'Session';
  id: string;
  duration?: Maybe<number>;
  playlist: Array<
    { __typename?: 'SessionPlaylistItem' } & PlaylistItemFragmentFragment
  >;
};

export type GetSessionHistoryQueryVariables = Exact<{
  userId: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;

export type GetSessionHistoryQuery = {
  __typename?: 'Query';
  sessions: {
    __typename?: 'SessionConnection';
    totalCount: number;
    pageCursors: {
      __typename?: 'PageCursors';
      first?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      last?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      next?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
      previous?: Maybe<{
        __typename?: 'PageCursor';
        cursor: string;
        pageNumber: number;
      }>;
    };
    nodes?: Maybe<
      Array<{ __typename?: 'Session' } & UserSessionPlaylistFragmentFragment>
    >;
  };
};

export type GetCompanyActiveLoginTypeQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyActiveLoginTypeQuery = {
  __typename?: 'Query';
  company: {
    __typename?: 'Company';
    id: string;
    activeLoginType?: Maybe<LoginType>;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  resetPassword: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'User'; id: string; hasPassword: boolean };
};

export type CheckUserDuplicityMutationVariables = Exact<{
  input: CheckUserDuplicityInput;
}>;

export type CheckUserDuplicityMutation = {
  __typename?: 'Mutation';
  checkUserDuplicity: {
    __typename?: 'CheckUserDuplicityResult';
    duplicate: boolean;
  };
};

export type GetUserDiagnosesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserDiagnosesQuery = {
  __typename?: 'Query';
  userDiagnoses: Array<{
    __typename?: 'UserDiagnosis';
    id: string;
    name: string;
  }>;
};

export type AuthUserQueryVariables = Exact<{ [key: string]: never }>;

export type AuthUserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName?: Maybe<string>;
    lastName?: Maybe<string>;
    languageCode: string;
    email?: Maybe<string>;
    phonePrefix?: Maybe<string>;
    phoneNumber?: Maybe<string>;
    role: UserRole;
    permissions: Array<UserPermission>;
    companyId?: Maybe<number>;
    subsidiaries: Array<number>;
    trainingCount: number;
  };
};

export type LanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type LanguagesQuery = {
  __typename?: 'Query';
  languages: Array<{ __typename?: 'Language'; code: string; name: string }>;
};

export type ChangeLanguageMutationVariables = Exact<{
  changeLanguageInput: ChangeLanguageInput;
}>;

export type ChangeLanguageMutation = {
  __typename?: 'Mutation';
  changeLanguage: { __typename?: 'User'; id: string; languageCode: string };
};

export const VrDeviceSceneGroupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VrDeviceSceneGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApplicationSceneGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mapSelectionEnabled' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mapSelectionId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VrDeviceSceneGroupFragmentFragment, unknown>;
export const SessionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SessionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Session' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SessionFragmentFragment, unknown>;
export const ApplicationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Application' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'package' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemApp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scenes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationFragmentFragment, unknown>;
export const SceneFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SceneFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApplicationScene' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parameters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationSceneGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SceneFragmentFragment, unknown>;
export const ApplicationSceneFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationSceneFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApplicationScene' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'SceneFragment' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manualUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationVersions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
              ],
            },
          },
        ],
      },
    },
    ...SceneFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ApplicationSceneFragmentFragment, unknown>;
export const ApplicationSceneGroupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApplicationSceneGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ApplicationSceneGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentScenes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicationSceneGroupFragmentFragment, unknown>;
export const CompanyFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Company' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentCompanyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultLoginType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpFaqId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasCustomLogo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyFragmentFragment, unknown>;
export const UserGroupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserGroupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserGroupFragmentFragment, unknown>;
export const ManualFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ManualFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Manual' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManualFragmentFragment, unknown>;
export const PatientAdminFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PatientAdminFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionCumulativeStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfSessions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allTimeSpent' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSession' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mostUsedScene' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatientAdminFragmentFragment, unknown>;
export const UserSessionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSessionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Session' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scene' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSessionFragmentFragment, unknown>;
export const VrDeviceFragmentForUpdateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VrDeviceFragmentForUpdate' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VrDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'status' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'battery' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateInProgress' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VrDeviceFragmentForUpdateFragment, unknown>;
export const PlaylistTemplateListFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlaylistTemplateListFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PlaylistTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ordinal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scene' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlaylistTemplateListFragmentFragment, unknown>;
export const CertificateTemplateFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CertificateTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CertificateTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendCcTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendToTrainees' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CertificateTemplateFragmentFragment, unknown>;
export const PlaylistTemplateFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlaylistTemplateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PlaylistTemplate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enforceOrder' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ordinal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scene' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'params' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value_t' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificateTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateTemplateFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CertificateTemplateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<PlaylistTemplateFragmentFragment, unknown>;
export const FolderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Folder' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Folder' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplatesCount' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FolderFragment, unknown>;
export const SessionPlaylistItemReportFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SessionPlaylistItemReportFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SessionPlaylistItemReport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationMs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentCompanyId' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countTowardsCompanies' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'launchedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scene' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SessionPlaylistItemReportFragmentFragment,
  unknown
>;
export const PlaylistItemFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlaylistItemFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SessionPlaylistItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ordinal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scene' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'params' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paramId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlaylistItemFragmentFragment, unknown>;
export const VrSessionFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VrSessionFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Session' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PlaylistItemFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    ...PlaylistItemFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<VrSessionFragmentFragment, unknown>;
export const UserTrainingCertificateFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserTrainingCertificateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTrainingCertificate' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trainingAssignmentId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserTrainingCertificateFragmentFragment, unknown>;
export const UserTrainingFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserTrainingFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserTrainingAssignment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validForDays' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gracePeriodDays' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trainingAssignmentId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enforceOrder' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificateTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scene' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name_t' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'params' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paramId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'valueId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value_t' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemsTotal' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemsCompleted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completionDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'certificate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'UserTrainingCertificateFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserTrainingCertificateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UserTrainingFragmentFragment, unknown>;
export const UserFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'diagnosis' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phonePrefix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'loginType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragmentFragment, unknown>;
export const UserDetailFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserDetailFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phonePrefix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionCumulativeStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numberOfSessions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDetailFragmentFragment, unknown>;
export const UserListFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserListFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trainingCompletion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'completedDate' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionCumulativeStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSession' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserListFragmentFragment, unknown>;
export const UserSessionPlaylistFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSessionPlaylistFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Session' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlist' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PlaylistItemFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlaylistItemFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UserSessionPlaylistFragmentFragment, unknown>;
export const GetUsersForSelectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersForSelect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UsersFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserFilterByInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '15' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'usersForSelect',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeSession' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersForSelectQuery,
  GetUsersForSelectQueryVariables
>;
export const SubmitFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedbackInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FeedbackInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'files' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Upload' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'files' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'files' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'feedbackInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'feedbackInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;
export const GetVrDeviceScenesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVrDeviceScenes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrDeviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrDeviceInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vrDeviceId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scenes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scene' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name_t' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parameters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name_t' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'required' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filtered' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'name_t',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'enabled',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ordinal',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'applicationSceneGroups',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'VrDeviceSceneGroupFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'version' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'application' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...VrDeviceSceneGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetVrDeviceScenesQuery,
  GetVrDeviceScenesQueryVariables
>;
export const CreateSessionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSessionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createSessionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createSessionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createSessionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vrDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSessionMutationMutation,
  CreateSessionMutationMutationVariables
>;
export const GetLastLaunchableSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLastLaunchableSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'EnumValue', value: 'STARTED_AT' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '2' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'playlist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ordinal' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scene' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name_t' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'params' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'paramId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name_t' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valueId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value_t' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLastLaunchableSessionsQuery,
  GetLastLaunchableSessionsQueryVariables
>;
export const GetLiveSessionsSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getLiveSessionsSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activeSessionsInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActiveSessionsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activeSessionsInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activeSessionsInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ActiveSessions' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SessionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SessionStarted' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'session' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SessionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UserAddedToSession' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'session' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SessionFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SessionEnded' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UserRemovedFromSession' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SessionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetLiveSessionsSubscriptionSubscription,
  GetLiveSessionsSubscriptionSubscriptionVariables
>;
export const UploadApkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadApk' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApkUploadChunkInfoInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadApk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmedChunk' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadApkMutation, UploadApkMutationVariables>;
export const GetApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'applications',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ApplicationFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicationsQuery,
  GetApplicationsQueryVariables
>;
export const GetApplicationDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'app' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetApplicationInputData' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'application' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'app' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'app' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApplicationFragment' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'version' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicationDetailQuery,
  GetApplicationDetailQueryVariables
>;
export const GetApplicationVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationVersionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationVersionInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationVersionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationVersionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'version' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'published' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'publishedAt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'usageStatistics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sessionCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scenes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ApplicationSceneFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicationVersionQuery,
  GetApplicationVersionQueryVariables
>;
export const GetAllApplicationScenesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllApplicationScenes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allApplicationScenes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationVersionId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllApplicationScenesQuery,
  GetAllApplicationScenesQueryVariables
>;
export const PublishApplicationVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'publishApplicationVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'versionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishApplicationVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationVersionInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationVersionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'versionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'published' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishApplicationVersionMutation,
  PublishApplicationVersionMutationVariables
>;
export const SetApplicationEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setApplicationEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplicationSetEnabledInputData' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setApplicationEnabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetApplicationEnabledMutation,
  SetApplicationEnabledMutationVariables
>;
export const EditApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'app' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditApplicationInputData' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'app' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'app' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ApplicationFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditApplicationMutation,
  EditApplicationMutationVariables
>;
export const CreateApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'app' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateApplicationInputData' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'app' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'app' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ApplicationFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;
export const GetApplicationSceneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationScene' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationSceneId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applicationVersionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationScene' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationSceneInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationSceneId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationSceneId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationVersionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applicationVersionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApplicationSceneFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicationSceneQuery,
  GetApplicationSceneQueryVariables
>;
export const EditApplicationSceneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editApplicationScene' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'scene' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditApplicationSceneInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'image' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editApplicationScene' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editApplicationSceneInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'scene' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'image' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'image' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ApplicationSceneFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditApplicationSceneMutation,
  EditApplicationSceneMutationVariables
>;
export const UploadApplicationSceneManualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadApplicationSceneManual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplicationSceneInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manual' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadApplicationSceneManual' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'manual' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'manual' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manualUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadApplicationSceneManualMutation,
  UploadApplicationSceneManualMutationVariables
>;
export const CreateApplicationSceneGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createApplicationSceneGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createApplicationSceneGroup' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateApplicationSceneGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApplicationSceneGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createApplicationSceneGroup' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createApplicationSceneGroup' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationSceneGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ApplicationSceneGroupFragment',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapSelectionEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapSelectionId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateApplicationSceneGroupMutation,
  CreateApplicationSceneGroupMutationVariables
>;
export const EditApplicationSceneGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editApplicationSceneGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editApplicationSceneGroupInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditApplicationSceneGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editApplicationSceneGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editApplicationSceneGroupInput' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'editApplicationSceneGroupInput',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationSceneGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ApplicationSceneGroupFragment',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapSelectionEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mapSelectionId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditApplicationSceneGroupMutation,
  EditApplicationSceneGroupMutationVariables
>;
export const GetApplicationSceneGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationSceneGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationSceneGroupFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationSceneGroupFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationSceneGroupOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationSceneGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'applicationSceneGroups',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ApplicationSceneGroupFragment',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicationSceneGroupsQuery,
  GetApplicationSceneGroupsQueryVariables
>;
export const GetApplicationSceneGroupsMapSelectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationSceneGroupsMapSelection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ApplicationSceneGroupFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'applicationSceneGroupsWithMapSelection',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationSceneGroupFilter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mapSelectionId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetApplicationSceneGroupsMapSelectionQuery,
  GetApplicationSceneGroupsMapSelectionQueryVariables
>;
export const GetApplicationSceneGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationSceneGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationSceneGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applicationSceneGroup' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applicationSceneGroupId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mapSelectionEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mapSelectionId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentScenes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ApplicationSceneFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicationSceneFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetApplicationSceneGroupQuery,
  GetApplicationSceneGroupQueryVariables
>;
export const ActivateUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'activateUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activateUsersInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivateUsersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activateUsersInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activateUsersInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ActivateUsersMutation,
  ActivateUsersMutationVariables
>;
export const CreateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createCompany' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createCompany' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createCompany' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'argument' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const EditCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editCompany' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editCompanyInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'editCompany' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CompanyFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EditCompanyMutation, EditCompanyMutationVariables>;
export const ChangeCompanyStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeCompanyStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChangeCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeCompanyStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'changeCompanyInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeCompanyStatusMutation,
  ChangeCompanyStatusMutationVariables
>;
export const GetDirectSubsidiariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDirectSubsidiaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subsidiaryInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubsidiaryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'directSubsidiaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subsidiaryInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subsidiaryInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CompanyFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetDirectSubsidiariesQuery,
  GetDirectSubsidiariesQueryVariables
>;
export const GetCompaniesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCompanies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CompanyFilterInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyFilter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CompanyFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetVrBoxesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVrBoxes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdOptionalInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdOptionalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrBoxes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyIdOptionalInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdOptionalInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetVrBoxesQuery, GetVrBoxesQueryVariables>;
export const CreateVrBoxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createVrBox' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrBoxInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateVrBoxInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVrBox' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrBoxInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vrBoxInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateVrBoxMutation, CreateVrBoxMutationVariables>;
export const UpdateCompanyLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompanyLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyLogo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hasCustomLogo' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyLogoMutation,
  UpdateCompanyLogoMutationVariables
>;
export const EditVrBoxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editVrBox' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrBoxInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditVrBoxInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editVrBox' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrBoxInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vrBoxInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditVrBoxMutation, EditVrBoxMutationVariables>;
export const DeleteVrBoxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteVrBox' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrBoxIdInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VrBoxIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteVrBox' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrBoxIdInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vrBoxIdInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteVrBoxMutation, DeleteVrBoxMutationVariables>;
export const GetCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CompanyFragment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentCompany' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statistics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeUsers' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apiKeys' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headsets' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vrBoxes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scenes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userGroups' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
              ],
            },
          },
        ],
      },
    },
    ...CompanyFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCompanyQuery, GetCompanyQueryVariables>;
export const AssignableApplicationScenesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AssignableApplicationScenes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignableApplicationScenes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignableApplicationScenesQuery,
  AssignableApplicationScenesQueryVariables
>;
export const CompanyApplicationScenesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyApplicationScenes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyApplicationScenes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyApplicationScenesQuery,
  CompanyApplicationScenesQueryVariables
>;
export const AddApplicationSceneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addApplicationScene' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companySceneInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanySceneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addApplicationScene' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySceneInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companySceneInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddApplicationSceneMutation,
  AddApplicationSceneMutationVariables
>;
export const AddAllApplicationScenesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addAllApplicationScenes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addAllApplicationScenes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyIdInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddAllApplicationScenesMutation,
  AddAllApplicationScenesMutationVariables
>;
export const RemoveApplicationSceneDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeApplicationScene' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companySceneInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanySceneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeApplicationScene' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySceneInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companySceneInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveApplicationSceneMutation,
  RemoveApplicationSceneMutationVariables
>;
export const GetUserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdOptionalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserGroupsQuery, GetUserGroupsQueryVariables>;
export const EditCompanyFeaturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editCompanyFeatures' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditCompanyFeaturesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editCompanyFeatures' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditCompanyFeaturesMutation,
  EditCompanyFeaturesMutationVariables
>;
export const CreateUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export const EditUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditUserGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUserGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserGroupFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditUserGroupMutation,
  EditUserGroupMutationVariables
>;
export const RemoveUserGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveUserGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserGroupMutation,
  RemoveUserGroupMutationVariables
>;
export const HelpFaqsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'helpFaqs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpFaqs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HelpFaqsQuery, HelpFaqsQueryVariables>;
export const CreateApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateApiKeyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const EditApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditApiKeyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditApiKeyMutation, EditApiKeyMutationVariables>;
export const RegenerateApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'regenerateApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegenerateApiKeyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'regenerateApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegenerateApiKeyMutation,
  RegenerateApiKeyMutationVariables
>;
export const GetApiKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApiKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetApiKeysInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apiKeys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApiKeysQuery, GetApiKeysQueryVariables>;
export const GetRolePermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRolePermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roles' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UserRole' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRolePermissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'roles' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'roles' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRolePermissionsQuery,
  GetRolePermissionsQueryVariables
>;
export const GetDebugReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDebugReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'debugReports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ordinal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reportData' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'scene' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDebugReportsQuery,
  GetDebugReportsQueryVariables
>;
export const CreateTrainingAssigmentForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createTrainingAssigmentForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingAssignmentUsersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTrainingAssigmentForUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playlistTemplateId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validForDays' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gracePeriodDays' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userGroupId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateTrainingAssigmentForUserMutation,
  CreateTrainingAssigmentForUserMutationVariables
>;
export const ManualsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Manuals' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'manuals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManualFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ManualFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ManualsQuery, ManualsQueryVariables>;
export const CreateManualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateManual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'manualInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateManualInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createManual' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'manualInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'manualInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateManualMutation,
  CreateManualMutationVariables
>;
export const SetManualEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetManualEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editManualInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditManualInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editManual' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editManualInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'editManualInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetManualEnabledMutation,
  SetManualEnabledMutationVariables
>;
export const EditManualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditManual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editManualInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditManualInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editManual' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editManualInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'editManualInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ManualFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ManualFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EditManualMutation, EditManualMutationVariables>;
export const DeleteManualDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteManual' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteManualInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteManualInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteManual' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteManualInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteManualInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManualMutation,
  DeleteManualMutationVariables
>;
export const CreatePatientAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPatientAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'createUserInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createUserInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'createUserInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PatientAdminFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PatientAdminFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreatePatientAdminMutation,
  CreatePatientAdminMutationVariables
>;
export const EditPatientAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editPatientAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editUserInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editUserInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'editUserInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditPatientAdminMutation,
  EditPatientAdminMutationVariables
>;
export const GetUsersAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsersAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UsersFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'users',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PatientAdminFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...PatientAdminFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUsersAdminQuery, GetUsersAdminQueryVariables>;
export const GetUserDetailAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserDetailAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userInput' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PatientAdminFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PatientAdminFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetUserDetailAdminQuery,
  GetUserDetailAdminQueryVariables
>;
export const GetUserSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SessionFilterInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SessionFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SessionOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'vrDeviceSessions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserSessionFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...UserSessionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetUserSessionsQuery,
  GetUserSessionsQueryVariables
>;
export const SuspendUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'suspendUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'suspendUsersInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SuspendUsersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'suspendUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'suspendUsersInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'suspendUsersInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SuspendUsersMutation,
  SuspendUsersMutationVariables
>;
export const SetVrDeviceOsUpdatesEnabledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setVrDeviceOsUpdatesEnabled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VrDeviceSysUpdateEnabledInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setVrDeviceOsUpdatesEnabled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'osUpdatesEnabled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetVrDeviceOsUpdatesEnabledMutation,
  SetVrDeviceOsUpdatesEnabledMutationVariables
>;
export const GetVrDevicesForSysUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVrDevicesForSysUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'VrDeviceFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'VrDeviceFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'VrDeviceOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevicesPaginated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'vrDevicesPaginated',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'osVersion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'osUpdatesEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceOwner' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVrDevicesForSysUpdateQuery,
  GetVrDevicesForSysUpdateQueryVariables
>;
export const GetVrDeviceDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVrDeviceDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrDeviceInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VrDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrDeviceInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vrDeviceInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vrBoxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'battery' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateInProgress' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVrDeviceDetailQuery,
  GetVrDeviceDetailQueryVariables
>;
export const GetVrDeviceStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVrDeviceStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrDeviceInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VrDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrDeviceInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'vrDeviceInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'battery' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateInProgress' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVrDeviceStatusQuery,
  GetVrDeviceStatusQueryVariables
>;
export const GetVrDeviceSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVrDeviceSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SessionFilterInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SessionFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SessionOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'vrDeviceSessions',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'playlist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scene' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name_t' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVrDeviceSessionsQuery,
  GetVrDeviceSessionsQueryVariables
>;
export const RegisterVrDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterVrDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceRegistration' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerVrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deviceRegistration' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deviceRegistration' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterVrDeviceMutation,
  RegisterVrDeviceMutationVariables
>;
export const EditVrDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditVrDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'editVrDeviceInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditVrDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editVrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editVrDeviceInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'editVrDeviceInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vrBoxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditVrDeviceMutation,
  EditVrDeviceMutationVariables
>;
export const LaunchUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'launchUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LaunchUrlInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'launchUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LaunchUrlMutation, LaunchUrlMutationVariables>;
export const DeleteVrDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteVrDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteVrDeviceInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VrDeviceIdInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteVrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteVrDeviceInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteVrDeviceInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteVrDeviceMutation,
  DeleteVrDeviceMutationVariables
>;
export const GetVrSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVrSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vrDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'battery' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updateInProgress' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playlist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scene' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name_t' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endedAt' } },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetVrSessionQuery, GetVrSessionQueryVariables>;
export const AppointUserToSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'appointUserToSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'addUserToSessionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddUserToSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserToSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addUserToSessionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'addUserToSessionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AppointUserToSessionMutation,
  AppointUserToSessionMutationVariables
>;
export const DisappointUserToSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'disappointUserToSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeUserFromSessionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveUserFromSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removeUserFromSessionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'removeUserFromSessionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  DisappointUserToSessionMutation,
  DisappointUserToSessionMutationVariables
>;
export const DeleteVrSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteVrSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteSessionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deleteSessionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deleteSessionInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteVrSessionMutation,
  DeleteVrSessionMutationVariables
>;
export const GetIceServerConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIceServerConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'iceServerConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iceServers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'urls' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'credentialType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'credential' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIceServerConfigQuery,
  GetIceServerConfigQueryVariables
>;
export const WebRtcCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'webRtcCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'candidate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WebRtcCandidateMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webRtcCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'candidate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'candidate' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebRtcCandidateMutation,
  WebRtcCandidateMutationVariables
>;
export const WebRtcNewOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'webRtcNewOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offerReq' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WebRtcNewOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webRtcNewOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offerReq' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offerReq' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebRtcNewOfferMutation,
  WebRtcNewOfferMutationVariables
>;
export const WebRtsAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'webRtsAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webRtsAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sdp' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebRtsAnswerSubscription,
  WebRtsAnswerSubscriptionVariables
>;
export const WebRtcDeviceCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'webRtcDeviceCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webRtcDeviceCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sdpMLineIndex' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sdpMid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'candidate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WebRtcDeviceCandidateSubscription,
  WebRtcDeviceCandidateSubscriptionVariables
>;
export const GetVrDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVrDevices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdOptionalInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdOptionalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyIdOptionalInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdOptionalInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upToDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vrBoxId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'session' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'battery' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateInProgress' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceOwner' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetVrDevicesQuery, GetVrDevicesQueryVariables>;
export const GetDevicesChangeSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'GetDevicesChangeSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyIdOptionalInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CompanyIdOptionalInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevicesChanges' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyIdOptionalInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyIdOptionalInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VrDeviceStatusUpdateEvent' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'battery' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateInProgress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VrDeviceRegistered' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'upToDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'battery' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updateInProgress',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deviceOwner' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VrDeviceAppsUpdated' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'upToDate' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'VrDeviceUnRegistered' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeviceSessionStarted' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeviceSessionEnded' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDevicesChangeSubscriptionSubscription,
  GetDevicesChangeSubscriptionSubscriptionVariables
>;
export const GetApplicationScenesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationScenes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApplicationSceneFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationScenes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'manualUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name_t' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filtered' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ordinal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'values' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name_t' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ordinal' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetApplicationScenesQuery,
  GetApplicationScenesQueryVariables
>;
export const GetHistoricPlaylistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHistoricPlaylist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sessionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playlist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ordinal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scene' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'params' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paramId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'valueId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHistoricPlaylistQuery,
  GetHistoricPlaylistQueryVariables
>;
export const UpdateAppsOnVrDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAppsOnVrDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrDeviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAppsOnVrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'vrDeviceId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vrDeviceId' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAppsOnVrDeviceMutation,
  UpdateAppsOnVrDeviceMutationVariables
>;
export const GetDeviceByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDeviceById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrDeviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vrDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vrDeviceInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vrDeviceId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vrBoxId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeviceByIdQuery, GetDeviceByIdQueryVariables>;
export const GetAvailableHelpFaqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAvailableHelpFaq' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableHelpFaq' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name_t' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content_t' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailableHelpFaqQuery,
  GetAvailableHelpFaqQueryVariables
>;
export const SendHelpFaqResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendHelpFaqResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sendHelpFaqResult' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SendHelpFaqResultInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendHelpFaqResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sendHelpFaqResult' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sendHelpFaqResult' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendHelpFaqResultMutation,
  SendHelpFaqResultMutationVariables
>;
export const GetPlaylistTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PlaylistTemplateFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PlaylistTemplateFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PlaylistTemplateOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PlaylistTemplateListFragment',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...PlaylistTemplateListFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplatesQuery,
  GetPlaylistTemplatesQueryVariables
>;
export const GetPlaylistTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'playlistTemplateId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PlaylistTemplateFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlaylistTemplateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplateQuery,
  GetPlaylistTemplateQueryVariables
>;
export const GetPlaylistTemplateCompaniesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplateCompanies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'playlistTemplateId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedToCompanies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplateCompaniesQuery,
  GetPlaylistTemplateCompaniesQueryVariables
>;
export const GetUnassignedPlaylistTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUnassignedPlaylistTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'GetUnassignedPlaylistTemplatesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unassignedPlaylistTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnassignedPlaylistTemplatesQuery,
  GetUnassignedPlaylistTemplatesQueryVariables
>;
export const GetFoldersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFolders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetCompanyFoldersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playlistTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFoldersQuery, GetFoldersQueryVariables>;
export const GetPlaylistTemplateFoldersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplateFolders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetPlaylistTemplateFoldersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplateFolders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplateFoldersQuery,
  GetPlaylistTemplateFoldersQueryVariables
>;
export const DeletePlaylistTemplateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePlaylistTemplateFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'DeletePlaylistTemplateFolderInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePlaylistTemplateFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePlaylistTemplateFolderMutation,
  DeletePlaylistTemplateFolderMutationVariables
>;
export const GetPlaylistTemplatesFoldersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplatesFolders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'PlaylistTemplatesFoldersFilterInput',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'PlaylistTemplatesFoldersFilterByInput',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'PlaylistTemplatesFoldersOrderByInput',
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'playlistTemplatesFolders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Folder' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...FolderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplatesFoldersQuery,
  GetPlaylistTemplatesFoldersQueryVariables
>;
export const GetPlaylistTemplateFolderCompaniesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplateFolderCompanies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetFolderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Folder' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedCompanies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FolderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplateFolderCompaniesQuery,
  GetPlaylistTemplateFolderCompaniesQueryVariables
>;
export const GetPlaylistTemplateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPlaylistTemplateFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetFolderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Folder' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'playlistTemplates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PlaylistTemplateListFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FolderFragmentDoc.definitions,
    ...PlaylistTemplateListFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetPlaylistTemplateFolderQuery,
  GetPlaylistTemplateFolderQueryVariables
>;
export const CreatePlaylistTemplateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPlaylistTemplateFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreatePlaylistTemplateFolderInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaylistTemplateFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Folder' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FolderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreatePlaylistTemplateFolderMutation,
  CreatePlaylistTemplateFolderMutationVariables
>;
export const EditPlaylistTemplateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editPlaylistTemplateFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditPlaylistTemplateFolderInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPlaylistTemplateFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Folder' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FolderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditPlaylistTemplateFolderMutation,
  EditPlaylistTemplateFolderMutationVariables
>;
export const ChangePlaylistTemplateCompanyAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changePlaylistTemplateCompanyAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PlaylistTemplateAssignmentInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'changePlaylistTemplateCompanyAssignment',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedToCompanies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePlaylistTemplateCompanyAssignmentMutation,
  ChangePlaylistTemplateCompanyAssignmentMutationVariables
>;
export const ChangePlaylistTemplateFolderCompanyAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'changePlaylistTemplateFolderCompanyAssignment',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'PlaylistTemplateFoldersAssignmentInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'changePlaylistTemplateFoldersCompanyAssignment',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignedToCompanies' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePlaylistTemplateFolderCompanyAssignmentMutation,
  ChangePlaylistTemplateFolderCompanyAssignmentMutationVariables
>;
export const CreatePlaylistTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPlaylistTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'template' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePlaylistTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPlaylistTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'template' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PlaylistTemplateFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlaylistTemplateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreatePlaylistTemplateMutation,
  CreatePlaylistTemplateMutationVariables
>;
export const EditPlaylistTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editPlaylistTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'template' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditPlaylistTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPlaylistTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'template' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PlaylistTemplateFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...PlaylistTemplateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditPlaylistTemplateMutation,
  EditPlaylistTemplateMutationVariables
>;
export const DeletePlaylistTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePlaylistTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePlaylistTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePlaylistTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePlaylistTemplateMutation,
  DeletePlaylistTemplateMutationVariables
>;
export const AddTrainingAssigmentForUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addTrainingAssigmentForUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingAssignmentUsersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTrainingAssigmentForUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTrainingAssigmentForUsersMutation,
  AddTrainingAssigmentForUsersMutationVariables
>;
export const AddTrainingAssigmentForUserGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addTrainingAssigmentForUserGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TrainingAssignmentUserGroupsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTrainingAssigmentForUserGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTrainingAssigmentForUserGroupsMutation,
  AddTrainingAssigmentForUserGroupsMutationVariables
>;
export const GetTrainingAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrainingAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingAssignmentFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trainingAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userGroupId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrainingAssignmentsQuery,
  GetTrainingAssignmentsQueryVariables
>;
export const GetTrainingAssignmentForTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrainingAssignmentForTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'playlistTemplateIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trainingAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'playlistTemplateIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'playlistTemplateIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserGroupFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserListFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserGroupFragmentFragmentDoc.definitions,
    ...UserListFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetTrainingAssignmentForTemplateQuery,
  GetTrainingAssignmentForTemplateQueryVariables
>;
export const CreateCertificateTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCertificateTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCertificateTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCertificateTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateTemplateFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CertificateTemplateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  CreateCertificateTemplateMutation,
  CreateCertificateTemplateMutationVariables
>;
export const EditCertificateTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editCertificateTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditCertificateTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editCertificateTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CertificateTemplateFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...CertificateTemplateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EditCertificateTemplateMutation,
  EditCertificateTemplateMutationVariables
>;
export const DeleteCertificateTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCertificateTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteCertificateTemplateInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCertificateTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCertificateTemplateMutation,
  DeleteCertificateTemplateMutationVariables
>;
export const GetSessionPlaylistItemReportsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getSessionPlaylistItemReportsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SessionPlaylistItemReportFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionPlaylistItemReportsMutation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SessionPlaylistItemReportFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SessionPlaylistItemReportFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetSessionPlaylistItemReportsMutationMutation,
  GetSessionPlaylistItemReportsMutationMutationVariables
>;
export const GetVrSessionDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVrSessionDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrSessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sessionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vrSessionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VrSessionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...VrSessionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetVrSessionDetailQuery,
  GetVrSessionDetailQueryVariables
>;
export const ObserveVrSessionChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'observeVrSessionChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'vrSessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionChange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sessionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'vrSessionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VrSessionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...VrSessionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ObserveVrSessionChangesSubscription,
  ObserveVrSessionChangesSubscriptionVariables
>;
export const EndVrSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'endVrSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endSessionInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EndSessionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'endSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endSessionInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endSessionInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VrSessionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...VrSessionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  EndVrSessionMutation,
  EndVrSessionMutationVariables
>;
export const LaunchNextInVrSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'launchNextInVrSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nextInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LaunchNextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'launchNextInVrSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nextInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VrSessionFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...VrSessionFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  LaunchNextInVrSessionMutation,
  LaunchNextInVrSessionMutationVariables
>;
export const StopSessionOnDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'stopSessionOnDevices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopSessionOnDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deviceIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deviceIds' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StopSessionOnDevicesMutation,
  StopSessionOnDevicesMutationVariables
>;
export const IncreaseDeviceVolumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'increaseDeviceVolume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'increaseDeviceVolume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IncreaseDeviceVolumeMutation,
  IncreaseDeviceVolumeMutationVariables
>;
export const DecreaseDeviceVolumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'decreaseDeviceVolume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decreaseDeviceVolume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DecreaseDeviceVolumeMutation,
  DecreaseDeviceVolumeMutationVariables
>;
export const RestartDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'restartDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deviceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restartDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deviceId' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RestartDeviceMutation,
  RestartDeviceMutationVariables
>;
export const UserTrainingReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userTrainingReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTrainingReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trainingReports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserTrainingReportsQuery,
  UserTrainingReportsQueryVariables
>;
export const UserTrainingReportDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userTrainingReportDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTrainingReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trainingDetail' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sequences' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'correctCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mistakes' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserTrainingReportDetailQuery,
  UserTrainingReportDetailQueryVariables
>;
export const GetUserTrainingAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTrainingAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTrainingAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserTrainingFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserTrainingFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetUserTrainingAssignmentsQuery,
  GetUserTrainingAssignmentsQueryVariables
>;
export const GetUserTrainingAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTrainingAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userTrainingId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTrainingAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userTrainingId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userTrainingId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserTrainingFragment' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserTrainingFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetUserTrainingAssignmentQuery,
  GetUserTrainingAssignmentQueryVariables
>;
export const GenerateUserTrainingCertificateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateUserTrainingCertificate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userTrainingId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateUserTrainingCertificate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userTrainingId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userTrainingId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'certificate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'UserTrainingCertificateFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserTrainingCertificateFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GenerateUserTrainingCertificateMutation,
  GenerateUserTrainingCertificateMutationVariables
>;
export const GetUserRuntimeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserRuntimeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sessionCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gameTimeSeconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'runTimeSeconds' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gameTimeLastSession' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gameTimeSeconds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'runTimeSeconds' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserRuntimeReportQuery,
  GetUserRuntimeReportQueryVariables
>;
export const GetUserRuntimeReportSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserRuntimeReportSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gameTimeProgressSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gameTimeSeconds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'runTimeSeconds' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserRuntimeReportSeriesQuery,
  GetUserRuntimeReportSeriesQueryVariables
>;
export const GetUserShoulderRangeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserShoulderRangeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldersRangeChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftChange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightChange' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserShoulderRangeReportQuery,
  GetUserShoulderRangeReportQueryVariables
>;
export const GetUserShoulderRangeReportSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserShoulderRangeReportSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldersRangeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxShoulderLeft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxShoulderRight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserShoulderRangeReportSeriesQuery,
  GetUserShoulderRangeReportSeriesQueryVariables
>;
export const GetUserArmsAbductionRangeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserArmsAbductionRangeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'armsAbductionChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftChange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightChange' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserArmsAbductionRangeReportQuery,
  GetUserArmsAbductionRangeReportQueryVariables
>;
export const GetUserArmsAbductionRangeReportSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserArmsAbductionRangeReportSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'armsAbductionSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxArmAbductionLeft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxArmAbductionRight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserArmsAbductionRangeReportSeriesQuery,
  GetUserArmsAbductionRangeReportSeriesQueryVariables
>;
export const GetUserArmsFlexReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserArmsFlexReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'armsFlexChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftChange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPercent' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightChange' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserArmsFlexReportQuery,
  GetUserArmsFlexReportQueryVariables
>;
export const GetUserArmsFlexReportSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserArmsFlexReportSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'armsFlexSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxArmFlexLeft' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxArmFlexRight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserArmsFlexReportSeriesQuery,
  GetUserArmsFlexReportSeriesQueryVariables
>;
export const GetUserFineMotoricsReportSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserFineMotoricsReportSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fineMotoricsSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPosition1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPosition2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPosition3' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPosition4' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftPosition5' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPosition1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPosition2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPosition3' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPosition4' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightPosition5' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserFineMotoricsReportSeriesQuery,
  GetUserFineMotoricsReportSeriesQueryVariables
>;
export const GetLimbMovementIntervalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLimbMovementIntervals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upperLimbMovementIntervals' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLimbMovementIntervalsQuery,
  GetLimbMovementIntervalsQueryVariables
>;
export const GetLimbMovementSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLimbMovementSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fromDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fromDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upperLimbMovementSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'left' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'x' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'y' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'z' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'right' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'x' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'y' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'z' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLimbMovementSeriesQuery,
  GetLimbMovementSeriesQueryVariables
>;
export const GetHeadMovementIntervalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHeadMovementIntervals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headMovementIntervals' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHeadMovementIntervalsQuery,
  GetHeadMovementIntervalsQueryVariables
>;
export const GetHeadMovementSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHeadMovementSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fromDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fromDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headMovementSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sceneName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'x' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'y' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'z' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHeadMovementSeriesQuery,
  GetHeadMovementSeriesQueryVariables
>;
export const GetHeadRotationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHeadRotationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headRotationRanges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxZ' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minZ' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headRotationSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxZ' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minZ' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHeadRotationDataQuery,
  GetHeadRotationDataQueryVariables
>;
export const GetUpperLimbActivityReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUpperLimbActivityReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upperLimbActivityChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftActiveMax' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftActiveFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftActiveLast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftTotalFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftTotalLast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightActiveMax' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightActiveFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightActiveLast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightTotalFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightTotalLast' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUpperLimbActivityReportQuery,
  GetUpperLimbActivityReportQueryVariables
>;
export const GetUpperLimbActivitySeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUpperLimbActivitySeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upperLimbActivitySeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'docCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftActive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightActive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightTotal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUpperLimbActivitySeriesQuery,
  GetUpperLimbActivitySeriesQueryVariables
>;
export const GetUserReactionTimeSeriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserReactionTimeSeries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seriesInterval' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SeriesAggregationInterval' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seriesInterval' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seriesInterval' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reactionTimeSeries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftArm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftFingers' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightArm' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightFingers' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserReactionTimeSeriesQuery,
  GetUserReactionTimeSeriesQueryVariables
>;
export const GetUserReactionTimeRangeReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserReactionTimeRangeReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'toDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'toDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'toDate' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'timezone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reactionTimeChange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftArmFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftArmLast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftFingersFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leftFingersLast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightArmFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightArmLast' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightFingersFirst' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rightFingersLast' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserReactionTimeRangeReportQuery,
  GetUserReactionTimeRangeReportQueryVariables
>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetUserDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserDetailFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserDetailFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUserDetailQuery, GetUserDetailQueryVariables>;
export const GetUserForPasswordChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserForPasswordChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserForPasswordChangeQuery,
  GetUserForPasswordChangeQueryVariables
>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'externalId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phonePrefix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserRole' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'diagnosis' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groups' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'loginType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LoginType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'createUserInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'externalId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'externalId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phonePrefix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phonePrefix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'diagnosis' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'diagnosis' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groups' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'username' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'password' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'loginType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'loginType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const GetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UsersFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserFilterByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UserOrderByInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'connection' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'key' },
                    value: {
                      kind: 'StringValue',
                      value: 'users',
                      block: false,
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserListFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...UserListFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const EditUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'externalId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phonePrefix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phoneNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groups' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserRole' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'diagnosis' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'loginType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LoginType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'editUserInput' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'externalId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'externalId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phonePrefix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phonePrefix' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'phoneNumber' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'diagnosis' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'diagnosis' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'groups' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groups' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'username' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'loginType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'loginType' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    ...UserFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<EditUserMutation, EditUserMutationVariables>;
export const AddTrainingAssigmentsForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addTrainingAssigmentsForUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingAssignmentsUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addTrainingAssigmentsForUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddTrainingAssigmentsForUserMutation,
  AddTrainingAssigmentsForUserMutationVariables
>;
export const RemoveTrainingAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeTrainingAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrainingAssignmentRemoveInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeTrainingAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveTrainingAssignmentsMutation,
  RemoveTrainingAssignmentsMutationVariables
>;
export const GetSessionHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSessionHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'direction' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'field' },
                      value: { kind: 'EnumValue', value: 'STARTED_AT' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageCursors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'next' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previous' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cursor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageNumber' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'UserSessionPlaylistFragment',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    ...UserSessionPlaylistFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GetSessionHistoryQuery,
  GetSessionHistoryQueryVariables
>;
export const GetCompanyActiveLoginTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCompanyActiveLoginType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companyId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeLoginType' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCompanyActiveLoginTypeQuery,
  GetCompanyActiveLoginTypeQueryVariables
>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resetPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResetPasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resetPasswordInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'resetPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPassword' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const CheckUserDuplicityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'checkUserDuplicity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CheckUserDuplicityInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkUserDuplicity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'duplicate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckUserDuplicityMutation,
  CheckUserDuplicityMutationVariables
>;
export const GetUserDiagnosesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserDiagnoses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userDiagnoses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserDiagnosesQuery,
  GetUserDiagnosesQueryVariables
>;
export const AuthUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AuthUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'languageCode' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phonePrefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subsidiaries' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trainingCount' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthUserQuery, AuthUserQueryVariables>;
export const LanguagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Languages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'languages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LanguagesQuery, LanguagesQueryVariables>;
export const ChangeLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'changeLanguageInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChangeLanguageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeLanguage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'changeLanguageInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'changeLanguageInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'languageCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeLanguageMutation,
  ChangeLanguageMutationVariables
>;
